import { CadLayout } from './layout-blueprints';

// Pages
import Page2 from './pages/Page2';
import PageCad1 from './pages/PageCad-1';
import Home from 'pages/Home';
// import PageCad2 from './pages/PageCad-2';
import PageDebugInfo from './pages/PageDebugInfo';
import Zones from './pages/Zones';
import NotificationsSearch from './Search/NotificationsSearch';
import EventsSearch from './Search/EventsSearch';
import SopsSearch from './Search/SopsSearch';
import UnitStatusSearch from './Search/UnitStatusSearch';
import Map from './pages/Map';
import BulletinSearch from './Search/BulletinSearch';
import ItemsSearch from './Search/ItemsSearch';
import UnitsSearch from './Search/UnitsSearch';
import VehiclesSearch from './Search/VehiclesSearch';
import AgenciesSearch from './Search/AgenciesSearch';
import AdvancedEventsSearch from './Search/AdvancedEventsSearch';
import NotesSearch from './Search/NotesSearch';
import EmployeesSearch from './Search/EmployeesSearch';
import NamesSearch from './Search/NamesSearch';
import CredentialsSearch from './Search/CredentialsSearch';
import ImagesSearch from './Search/ImagesSearch';
import PhoneNumbersSearch from './Search/PhoneNumbersSearch';
import PeopleSearch from './Search/PeopleSearch';
import AddressesSearch from './Search/AddressesSearch';
import AdvancedAddressesSearch from './Search/AdvancedAddressesSearch';
import PlacesSearch from './Search/PlacesSearch';
import VehicleTowingsSearch from './Search/VehicleTowingsSearch';
import ZonesSearch from './Search/ZonesSearch';
import RotationsSearch from './Search/RotationsSearch';
import QueriesSearch from './Search/QueriesSearch';
import EventsPage from './pages/EventsPage';
import UnitsPage from './pages/UnitsPage';
import PopOver from 'components/PopOver';

export const defaultUrl = '/home';

export default [
  { url: '/page2/:tab?', component: Page2, layout: CadLayout },
  { url: '/alt-home', component: PageCad1, layout: CadLayout },
  // { url: '/cad-2', component: PageCad2, layout: CadLayout },
  { url: '/home', component: Home, layout: CadLayout },
  { url: '/events', component: EventsPage, layout: CadLayout },
  { url: '/units', component: UnitsPage, layout: CadLayout },
  { url: '/map', component: Map, layout: CadLayout },
  { url: '/debug-info', component: PageDebugInfo, layout: CadLayout },
  { url: '/zones', component: Zones, layout: CadLayout },
  { url: '/search/notifications', component: NotificationsSearch, layout: CadLayout },
  { url: '/search/events', component: EventsSearch, layout: CadLayout },
  { url: '/search/sops', component: SopsSearch, layout: CadLayout },
  { url: '/search/unit-status', component: UnitStatusSearch, layout: CadLayout },
  { url: '/search/bulletin', component: BulletinSearch, layout: CadLayout },
  { url: '/search/items', component: ItemsSearch, layout: CadLayout },
  { url: '/search/vehicles', component: VehiclesSearch, layout: CadLayout },
  { url: '/search/units', component: UnitsSearch, layout: CadLayout },
  { url: '/search/agencies', component: AgenciesSearch, layout: CadLayout },
  { url: '/search/advanced-events', component: AdvancedEventsSearch, layout: CadLayout },
  { url: '/search/notes', component: NotesSearch, layout: CadLayout },
  { url: '/search/employees', component: EmployeesSearch, layout: CadLayout },
  { url: '/search/names', component: NamesSearch, layout: CadLayout },
  { url: '/search/credentials', component: CredentialsSearch, layout: CadLayout },
  { url: '/search/images', component: ImagesSearch, layout: CadLayout },
  { url: '/search/phone-numbers', component: PhoneNumbersSearch, layout: CadLayout },
  { url: '/search/people', component: PeopleSearch, layout: CadLayout },
  { url: '/search/addresses', component: AddressesSearch, layout: CadLayout },
  { url: '/search/advanced-addresses', component: AdvancedAddressesSearch, layout: CadLayout },
  { url: '/search/places', component: PlacesSearch, layout: CadLayout },
  { url: '/search/vehicle-towings', component: VehicleTowingsSearch, layout: CadLayout },
  { url: '/search/zones', component: ZonesSearch, layout: CadLayout },
  { url: '/search/rotation-history', component: RotationsSearch, layout: CadLayout },
  { url: '/search/ncic-queries', component: QueriesSearch, layout: CadLayout },
  { url: '/test', component: PopOver, layout: CadLayout },
];
