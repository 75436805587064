import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeEditUnit } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { handleError } from 'reducers/ErrorReducer';
import UnitEdit from './UnitEdit';
import UnitResources from './UnitResources';


const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  content: {
    margin: -theme.spacing(3),
  },
}));

function EditUnitDialog(props) {
  const classes = useStyles();
  const { ptsUnitID } = props;
  const [tab, setTab] = useState('edit');
  const mountedRef = useRef(true);
  useEffect(() => {
    if (props.tab) setTab(props.tab);

    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, [props.tab]);

  const close = (data) => {
    props.closeEditUnit(data);
  };

  return (
    <Dialog
      toolbar
      onClose={close}
      title='Edit Unit'
      fullScreen>
      <div className={classes.content}>
        <Paper square>
          <Tabs variant="scrollable" value={tab} onChange={(ev, val) => setTab(val)}>
            <Tab label="Edit" value="edit" />
            <Tab label="Resources" value="resources" />
          </Tabs>
        </Paper>
        {tab === 'edit' && <div className={classes.wrap}>
          <UnitEdit ptsUnitID={ptsUnitID} />
        </div>}
        {tab === 'resources' && <UnitResources ptsUnitID={ptsUnitID} />}
      </div>
    </Dialog>
  );
}

export default connect(null, {
  closeEditUnit,
  handleError,
})(EditUnitDialog);
