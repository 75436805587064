import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { getRotationUnits, updateRotationUnits } from 'reducers/RotationReducer';
import { handleError } from 'reducers/ErrorReducer';
import { XGrid } from '@material-ui/x-grid';
import { sortObjArr } from 'utils/functions';
import { assignUnitToEvent } from 'reducers/UnitStatusReducer';
import CancelIcon from '@material-ui/icons/Cancel';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import PublishIcon from '@material-ui/icons/Publish';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import { addEventNote } from 'reducers/EventsReducer';
import { asyncForEach } from 'utils/functions';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import VehicleLookup from 'components/VehicleLookup';
import { addVehicleToEvent } from 'reducers/EventsReducer';
import Checkbox2 from 'components/Checkbox2';
import { closeRotations, showTowingDialog } from 'reducers/DialogsReducer';
import { gridStyle, RowInner } from 'utils/formStyles';
import { getEventVehicles } from 'reducers/EventsReducer';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';
const useStyles = makeStyles(() => ({
  longField: gridStyle(220, '100%'),
  content: {
    width: 1000,
    maxWidth: '100%',
  },
  toDispatch: {
    height: 95,
    marginBottom: 15,
  },
  resList: {
    height: 300,
  },
  vehicle: {
    marginTop: 24,
    marginBottom: 8,
  },
}));

const renderSkip = (params) => {
  const { skip } = params.row;
  return (
    <IconButton onClick={skip}>
      <CancelIcon />
    </IconButton>
  );
};

const dispatchColumns = [
  { field: 'id', headerName: 'Skip', width: 80, renderCell: renderSkip },
  { field: 'Unit', headerName: 'Unit', width: 100 },
  { field: 'UnitResources', headerName: 'Resources', width: 200 },
  { field: 'Info', headerName: 'Info', width: 200 },
  { field: 'UnitStatus', headerName: 'Unit Status', width: 150 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 150 },
  { field: 'PlaceName', headerName: 'Place Name', width: 150 },
  { field: 'Position', headerName: 'Position', width: 80 },
];

const renderCellMoveFirst = (params) => {
  const { moveFirst, Position } = params.row;
  return (
    <IconButton onClick={() => moveFirst(Position)}>
      <PublishIcon />
    </IconButton>
  );
};

const renderCellOwnerReqest = (params) => {
  const { ownerRequest, Position } = params.row;
  return (
    <IconButton onClick={() => ownerRequest(Position)}>
      <EmojiPeopleIcon />
    </IconButton>
  );
};

const unitColumns = [
  { field: 'MF', headerName: 'Move First', width: 80, renderCell: renderCellMoveFirst },
  { field: 'OR', headerName: 'Owner Request', width: 100, renderCell: renderCellOwnerReqest },
  { field: 'Unit', headerName: 'Unit', width: 100 },
  { field: 'UnitResources', headerName: 'Resources', width: 150 },
  { field: 'Info', headerName: 'Info', width: 150 },
  { field: 'UnitStatus', headerName: 'Unit Status', width: 150 },
  { field: 'AgencyID', headerName: 'Agency ID', width: 130 },
  { field: 'PlaceName', headerName: 'Place Name', width: 150 },
  { field: 'Position', headerName: 'Position', width: 80 },
];

function RotationsDialog(props) {
  const classes = useStyles();
  const { Agencies } = props;
  const { ptsEventID, EventID, Rotation } = props.data;
  const {
    Code,
    Description,
    IncludeOwnerRequestOnEvent,
    IncludeSkipOnEvent,
    IsOwnerReasonRequired,
    IsSkipReasonRequired,
    OwnerType,
    SkipType,
  } = Rotation;

  const [Units, setUnits] = useState([]);
  const [showSkipDialog, setShowSkipDialog] = useState(false);
  const [showMoveDialog, setShowMoveDialog] = useState(false);
  const [showOwnerDialog, setShowOwnerDialog] = useState(false);
  const [reason, setReason] = useState('');
  const [place, setPlace] = useState('Remain');
  const [dispatchSeq, setDispatchSeq] = useState(0);
  const [selected, setSelected] = useState(0);
  const [notes, setNotes] = useState([]);
  const [remainInPlace, setRemainInPlace] = useState(false);
  const [vehicle, setVehicle] = useState(null);
  const [towing, setTowing] = useState(false);
  const [Vehicles, setVehicles] = useState([]);
  const [vehiclesLoaded, setVehiclesLoaded] = useState(false);
  const [rotationsLoaded, setRotationsLoaded] = useState(false);
  const mountedRef = useRef(true);
  const toDispatch = Units.length ? [Units[dispatchSeq]] : [];
  const loaded = vehiclesLoaded && rotationsLoaded;
  const canReadAgencies = getAgenciesFullPermission('cad', 'Units', 'Read');
  useEffect(() => {
    getRotations();
    getVehicles();
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  const getVehicles = async () => {
    try {
      const Vehicles = await getEventVehicles(ptsEventID);
      if (!mountedRef.current) return;
      setVehicles(Vehicles.map((v) => ({ ...v, id: v.ptsVehicleID })));
      setVehiclesLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const getRotations = async () => {
    try {
      const Rotations = await getRotationUnits(Rotation.Code);
      if (!mountedRef.current) return;
      const units = filterUnits(Rotations);
      setUnits(units);
      setRotationsLoaded(true);
    } catch (err) {
      props.handleError(err);
    }
  };

  const displaySkipDialog = () => {
    setReason('');
    setShowSkipDialog(true);
    if (SkipType === 'Choose') {
      setPlace('Remain');
    } else {
      setPlace(SkipType);
    }
  };

  const displayMoveFirst = (Position) => {
    setSelected(Position);
    setReason('');
    setShowMoveDialog(true);
  };

  const displayOwnerRequest = (Position) => {
    setSelected(Position);
    setReason('');
    setShowOwnerDialog(true);
    if (OwnerType === 'Choose') {
      setPlace('Remain');
    } else {
      setPlace(OwnerType);
    }
  };

  const skipUnit = async () => {
    setRemainInPlace(false);
    const Unit = Units[dispatchSeq];
    const { AgencyID, ptsUnitID } = Unit;
    const note = `Unit [${Unit.Unit}] was skipped in [${AgencyID}] : ${reason}`;
    const Position = getUnitPosition();
    const data = {
      Rotation: Code,
      ptsUnitID,
      ptsEventID,
      ActionReason: reason,
      ACTION: 'Skip',
      place,
      Position,
    };
    await updateUnit(data);
    if (IncludeSkipOnEvent) {
      const newNotes = [...notes, note];
      setNotes(newNotes);
    }
    if (place === 'Remain') {
      let seq = dispatchSeq + 1;
      if (seq > Units.length - 1) seq = 0;
      setDispatchSeq(seq);
    } else {
      const units = [...Units];
      units.splice(dispatchSeq, 1);
      units.push(Unit);
      setUnits(units.map((unit, idx) => ({ ...unit, Position: idx + 1 })));
    }
  };

  const updateUnit = async (data) => {
    props.showSpinner();
    try {
      await updateRotationUnits(data);
    } catch (err) {
      props.handleError(err, 'Error updating unit information');
    }
    props.hideSpinner();
  };

  const getUnitPosition = (Action) => {
    if (place === 'Remain' && Action !== 'MoveFirst' && Action !== 'Dispatch') return false;
    let Position;
    switch (Action) {
      case 'MoveFirst':
        Position = Units.reduce((res, val) => Math.min(res, val.Pos), 999999) - 1;
        break;
      default:
        Position = Units.reduce((res, val) => Math.max(res, val.Pos), 0) + 1;
    }
    return Position;
  };

  const MoveUnitFirst = async () => {
    setRemainInPlace(false);
    const units = [...Units];
    const unit = units.splice(selected - 1, 1);
    const { ptsUnitID } = unit[0];
    const Position = getUnitPosition('MoveFirst');
    units.unshift(unit[0]);
    setUnits(units.map((unit, idx) => ({ ...unit, Position: idx + 1 })));
    const data = {
      Rotation: Code,
      ptsUnitID,
      ptsEventID,
      ActionReason: reason,
      ACTION: 'MoveFirst',
      place,
      Position,
    };
    await updateUnit(data);
  };

  const RequestUnit = async () => {
    const Unit = Units[selected - 1];
    const { AgencyID, ptsUnitID } = Unit;
    const note = `Unit [${Unit.Unit}] in [${AgencyID}] was dispatched by request.: ${reason}`;
    if (IncludeOwnerRequestOnEvent) {
      const newNotes = [...notes, note];
      setNotes(newNotes);
    }
    setRemainInPlace(true);
    const Position = getUnitPosition();
    const data = {
      Rotation: Code,
      ptsUnitID,
      ptsEventID,
      ActionReason: reason,
      ACTION: 'Requested',
      place,
      Position,
    };
    await updateUnit(data);
    if (place === 'Remain') {
      setDispatchSeq(selected - 1);
      data.position = false;
    } else {
      const units = [...Units];
      units.splice(selected - 1, 1);
      units.push(Unit);
      setUnits(units.map((unit, idx) => ({ ...unit, Position: idx + 1 })));
      setDispatchSeq(units.length - 1);
    }
  };

  const dispatch = async () => {
    const ptsUnitID = toDispatch[0].ptsUnitID;
    const units = [...Units];
    if (!remainInPlace) {
      const idx = units.findIndex((u) => u.ptsUnitID === ptsUnitID);
      const unit = units.splice(idx, 1);
      units.push(unit[0]);
    }
    props.showSpinner();
    props.assignUnitToEvent(ptsUnitID, ptsEventID);
    const Position = remainInPlace ? false : getUnitPosition('Dispatch');
    const data = {
      Rotation: Code,
      ptsUnitID,
      ptsEventID,
      ActionReason: reason,
      ACTION: 'Dispatched',
      place,
      Position,
    };
    await updateRotationUnits(data);
    try {
      await asyncForEach(notes, async (note) => {
        await addEventNote(note, ptsEventID);
      });
    } catch (err) {
      props.handleError(err, 'Error updating event notes');
      return props.hideSpinner();
    }
    if (vehicle) {
      const { ptsVehicleID } = vehicle;
      if (!isVehicleInEvent()) {
        try {
          await addVehicleToEvent(ptsEventID, ptsVehicleID);
        } catch (err) {
          props.handleError(err, 'Error adding vehicle to event');
          return props.hideSpinner();
        }
      }
      if (towing) {
        const { AgencyID } = toDispatch[0];
        const ptsPlaceID = Agencies.find((a) => a.AgencyID === AgencyID)?.ptsPlaceID || null;
        props.showTowingDialog({ ptsVehicleID, ptsPlaceID, ptsUnitID, ptsEventID });
      }
    }
    props.hideSpinner();
    close();
  };

  const isVehicleInEvent = () => {
    const { ptsVehicleID } = vehicle;
    return Boolean(Vehicles.find((v) => v.ptsVehicleID === ptsVehicleID));
  };

  const filterUnits = (Rotations) => {
    let units = Rotations.filter((unit) => canReadAgencies.indexOf(unit.AgencyID) !== -1).map(
      (unit) => {
        return {
          ...unit,
          id: unit.ptsUnitID,
          Pos: Rotations.find((r) => r.ptsUnitID === unit.ptsUnitID).Position,
          skip: displaySkipDialog,
          moveFirst: displayMoveFirst,
          ownerRequest: displayOwnerRequest,
        };
      }
    );
    return sortObjArr(units, 'Position').map((unit, idx) => ({ ...unit, Position: idx + 1 }));
  };

  const close = () => {
    props.closeRotations();
  };

  const renderActions = () => {
    return (
      <>
        <Button
          color="primary"
          variant="contained"
          autoFocus
          onClick={dispatch}
          disabled={!toDispatch.length}>
          <SaveIcon /> Save &amp; Dispatch
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderSkipUnit = () => {
    const saveAndClose = () => {
      setShowSkipDialog(false);
      skipUnit();
    };
    const actions = (
      <Button
        color="primary"
        variant="contained"
        autoFocus
        onClick={saveAndClose}
        disabled={IsSkipReasonRequired && !reason.length}>
        <SaveIcon /> Save &amp; Close
      </Button>
    );

    return (
      <Dialog
        toolbar
        onClose={() => setShowSkipDialog(false)}
        title="Skip Unit"
        width="md"
        actions={actions}>
        <TextField
          label="Reasons for skipping unit"
          multiline
          rows={4}
          value={reason}
          variant="outlined"
          onChange={(ev) => setReason(ev.target.value)}
          style={{ width: '100%' }}
        />
        <FormControl component="fieldset">
          <RadioGroup
            value={place}
            onChange={(ev) => setPlace(ev.target.value)}
            style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="Remain"
              control={<Radio />}
              label="Remain in Place"
              disabled={SkipType !== 'Choose'}
            />
            <FormControlLabel
              value="Last"
              control={<Radio />}
              label="Place Last"
              disabled={SkipType !== 'Choose'}
            />
          </RadioGroup>
        </FormControl>
      </Dialog>
    );
  };

  const renderMoveFirst = () => {
    const saveAndClose = () => {
      setShowMoveDialog(false);
      MoveUnitFirst();
    };
    const actions = (
      <Button
        color="primary"
        variant="contained"
        autoFocus
        onClick={saveAndClose}
        disabled={!reason.length}>
        <SaveIcon /> Save &amp; Close
      </Button>
    );
    return (
      <Dialog
        toolbar
        onClose={() => setShowMoveDialog(false)}
        title="Move Unit"
        width="md"
        actions={actions}>
        <TextField
          label="Reasons for change in rotation order"
          multiline
          rows={4}
          value={reason}
          variant="outlined"
          onChange={(ev) => setReason(ev.target.value)}
          style={{ width: '100%' }}
        />
      </Dialog>
    );
  };

  const renderOwnerReqest = () => {
    const saveAndClose = () => {
      setShowOwnerDialog(false);
      RequestUnit();
    };
    const actions = (
      <Button
        color="primary"
        variant="contained"
        autoFocus
        onClick={saveAndClose}
        disabled={IsOwnerReasonRequired && !reason.length}>
        <SaveIcon /> Save &amp; Close
      </Button>
    );
    return (
      <Dialog
        toolbar
        onClose={() => setShowOwnerDialog(false)}
        title="Request Unit"
        width="md"
        actions={actions}>
        <TextField
          label="Reasons for requesting unit"
          multiline
          rows={4}
          value={reason}
          variant="outlined"
          onChange={(ev) => setReason(ev.target.value)}
          style={{ width: '100%' }}
        />
        <FormControl component="fieldset">
          <RadioGroup
            value={place}
            onChange={(ev) => setPlace(ev.target.value)}
            style={{ flexDirection: 'row' }}>
            <FormControlLabel
              value="Remain"
              control={<Radio />}
              label="Remain in Place"
              disabled={OwnerType !== 'Choose'}
            />
            <FormControlLabel
              value="Last"
              control={<Radio />}
              label="Place Last"
              disabled={OwnerType !== 'Choose'}
            />
          </RadioGroup>
        </FormControl>
      </Dialog>
    );
  };

  return (
    <>
      <Dialog
        toolbar
        onClose={close}
        title={`Event: ${EventID}, Rotation: ${Description}`}
        actions={renderActions()}
        maxWidth={false}>
        <div className={classes.content}>
          <h5>Resources to Dispatch</h5>
          <XGrid
            columns={dispatchColumns}
            rows={toDispatch}
            loading={!loaded}
            density={'compact'}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            className={classes.toDispatch}
            hideFooter
          />
          <RowInner className={classes.vehicle}>
            <VehicleLookup
              onChange={setVehicle}
              label="Add Vehicle"
              className={classes.longField}
              options={Vehicles}
            />
            <Checkbox2
              label="Add Towing"
              val={towing}
              onChange={(ev, val) => setTowing(val)}
              disabled={!vehicle}
            />
          </RowInner>
          <h5>Resource Rotation List</h5>
          <XGrid
            columns={unitColumns}
            rows={Units}
            loading={!loaded}
            density={'compact'}
            disableMultipleSelection={true}
            showToolbar
            disableColumnFilter
            className={classes.resList}
          />
        </div>
      </Dialog>
      {showSkipDialog && renderSkipUnit()}
      {showMoveDialog && renderMoveFirst()}
      {showOwnerDialog && renderOwnerReqest()}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    Agencies: state.dictionary.Agencies,
  };
};

export default connect(mapStateToProps, {
  closeRotations,
  handleError,
  assignUnitToEvent,
  showSpinner,
  hideSpinner,
  showTowingDialog,
})(RotationsDialog);
