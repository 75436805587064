import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import SearchDatePicker from '../components/SearchDatePicker2';
import SearchTimePicker from 'Search/components/SearchTimePicker';
import { searchAdvancedEvents } from 'reducers/SearchReducer';
import { showReport, editEvent } from 'reducers/DialogsReducer';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import EditIcon from '@material-ui/icons/Edit';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { notify } from 'reducers/NotifierReducer';
import { copyEvent, pushEvToLegacyRMS } from 'reducers/EventsReducer';
import { showAddAttachment } from 'reducers/DialogsReducer';
import { getFullPermissions, getAccessPermission } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import { reinstateEvent } from 'reducers/EventsReducer';
import OpenInBrowserIcon from '@material-ui/icons/OpenInBrowser';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import DateAndTime from 'Search/components/DateAndTime';
import Checkbox2 from 'components/Checkbox2';
import { getService } from 'reducers/service';
import WhatshotIcon from '@material-ui/icons/Whatshot';
import TextField2 from 'components/TextField2';
import { getTimeFromDate } from 'reducers/TimeReducer';
import { sendRipAndRun } from 'reducers/ReportsReducer';
import { isEligibleForExport } from 'reducers/ReportsReducer';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
//import LocalFireDepartmentIcon from '@mui/icons-material/LocalFireDepartment';
import IosShareIcon from '@mui/icons-material/IosShare';
import SendIcon from '@mui/icons-material/Send';
import Agency from 'Search/AgenciesSearch/Agency';
import DeviceHubIcon from '@material-ui/icons/DeviceHub';
import SystemUpdateAltIcon from '@mui/icons-material/SystemUpdateAlt';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  checkbox: {
    marginTop: 22,
    marginLeft: 8,
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const colsWithDeleted = [
  'EventID',
  'OriginalEventType',
  'EventType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'PartyName',
  'PartyRelationship',
  'Disposition',
  'Zone',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'DeletedBy',
  'DeletedIP',
];

const colsWithoutDelete = [
  'EventID',
  'OriginalEventType',
  'EventType',
  'Status',
  'CaseID',
  'FullAddress',
  'StreetName',
  'AgencyID',
  'PartyName',
  'PartyRelationship',
  'Disposition',
  'Zone',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
];

function AdvancedEvent(props) {
  const classes = useStyles();
  const permissions = getFullPermissions('cad', 'Events', 'any');
  const editClosedEvents = getAccessPermission('cad', 'Edit Closed Events', 'any');
  const { filter, dataUpdate, dictionary, isEventExportEnabled } = props;
  const [rows, setRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  const [viewMenuAnchor, setViewMenuAnchor] = useState(null);
  const [shiftRepShown, setShiftRepShown] = useState(false);
  const [columns, setColumns] = useState(
    permissions.ViewDeleted ? colsWithDeleted : colsWithoutDelete
  );
  const [selectionStatus, setSelectionStatus] = useState(null);
  const [printFlag, setPrintFlag] = useState(false);
  const [ViewDeleted, setViewDeleted] = useState(false);
  const [text, setText] = useState('');
  const [fireRMSActive, setFireRMSActive] = useState(false);
  const [eventExportToLegacyRMS, setEventExportToLegacyRMS] = useState(false);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [mailEventReport, setMailEventReport] = useState(false);
  const [pushToLegacyPermission, setPushToLegacyPermission] = useState(false);
  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate?.type === 'event') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [ViewDeleted]);

  useEffect(() => {
    checkFireRMS();
    checkMailEventReport();
    checkEventExportToLegacyRMS();
    checkPushToLegacyRMS();
    // eslint-disable-next-line
  }, [selection]);

  const checkEventExportToLegacyRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'LawEnforcementRMSExport';
    const agencyType = 'single';
    setEventExportToLegacyRMS(isEligibleForExport(item, permissionType, agencyType));
  };

  const checkPushToLegacyRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'PushToLegacyRMS';
    const agencyType = 'single';
    setPushToLegacyPermission(isEligibleForExport(item, permissionType, agencyType));
  };

  const handleEventExportToLegacy = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID, AgencyID } = item;

    const service = getService('event-export-legacy-rms');
    try {
      await service.create({ ptsEventID, AgencyID });
      //props.notify('Event exported', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };
  const handleEventExport = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;

    const service = getService('event-export-global');
    try {
      await service.create({ ptsEventID });
      //props.notify('Event exported', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const ripAndRun = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    try {
      await sendRipAndRun(item.ptsEventID);
      //props.notify('Report sent', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };
  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const fireRMSexport = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID, AgencyID } = item;

    const service = getService('cad-fire-rms-report');
    try {
      await service.create({ ptsEventID, AgencyID });
      //props.notify('Event exported', 'success');
    } catch (err) {
      props.handleError(err);
    }
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return { ...row, id: idx + nextStartingIndex };
    });
  };

  const search = (offset = 0, mergeRows = false) => {
    setLoaded(false);
    const startTime = timeFrom ? getTimeFromDate(timeFrom) : timeFrom;
    const endTime = timeFrom ? getTimeFromDate(timeTo) : timeTo;
    searchAdvancedEvents(
      text,
      dateFrom,
      dateTo,
      startTime,
      endTime,
      filter,
      ViewDeleted,
      offset,
      limit
    )
      .then((result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);
        const nextStartingIndex = rows.length;
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const viewInternalReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    props.showReport({ id: 24, data: { ptsEventID: item.ptsEventID }, title: 'Event Details' });
  };

  const viewExternalReport = () => {
    const item = rows.find((row) => row.id === selection);
    closeDetailsMenu();
    if (!item) return;
    props.showReport({ id: 72, data: { ptsEventID: item.ptsEventID }, title: 'Event Details' });
  };

  const showShiftReportDialog = () => {
    closeDetailsMenu();
    setShiftRepShown(true);
  };

  const showBatchReportDialog = () => {
    const EventIDs = rows.map((item) => item.ptsEventID).join(',');
    props.showReport({ id: 119, data: { EventIDs }, title: 'Event Lists' });
  };

  const showDetailsMenu = (ev) => {
    setViewMenuAnchor(ev.currentTarget);
  };

  const closeDetailsMenu = () => {
    setViewMenuAnchor(null);
  };

  const editEvent = () => {
    const item = rows.find((row) => row.id === selection);
    props.editEvent(item.ptsEventID);
  };

  const handleCopyEvent = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    try {
      const newEvent = await copyEvent(item.ptsEventID);
      if (newEvent) {
        //props.notify(`Created event: ${newEvent.EventID}`, 'success');
      }
    } catch (err) {
      props.handleError(err);
    }
  };

  const checkFireRMS = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'FireHouseEnabled';
    const eventType = 'single';
    setFireRMSActive(isEligibleForExport(item, permissionType, eventType));
  };

  const checkMailEventReport = async () => {
    if (typeof selection !== 'number') return;
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const permissionType = 'EmailReportOnEventClose';
    const agencyType = 'single';
    setMailEventReport(isEligibleForExport(item, permissionType, agencyType));
  };

  const handleMailEventReport = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    try {
      const { ptsEventID } = item;
      const Agencies = dictionary.Agencies;
      const AgencyID = Agencies.filter(
        (a) => a.AgencyID === item.AgencyID && a.EmailReportOnEventClose
      ).map((item) => item.AgencyID);
      if (!AgencyID) return;
      const service = getService('mail-event-report-to-agency');
      await service.create({ ptsEventID, AgencyIDs: AgencyID });
      props.notify('Report sent', 'success');
    } catch (error) {
      console.log('error ', error);
      props.handleError(error);
    }
  };

  const pushToLegacy = async () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    props.pushEvToLegacyRMS(ptsEventID, true);
    //setPushToLegacyActive(false);
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsEventID } = item;
    props.showAddAttachment({ id: ptsEventID, type: 'Event' });
  };

  const reinstate = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    const { ptsEventID } = item;
    props.reinstateEvent(ptsEventID);
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
    closeDetailsMenu();
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };

  const actions = () => {
    const canEdit = permissions.Edit && (selectionStatus !== 'closed' || editClosedEvents);
    const canReinstate = permissions.Edit && selectionStatus === 'closed' && editClosedEvents;

    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <span>
            <Fab onClick={showDetailsMenu} size="small" color="secondary">
              <LocalPrintshopRoundedIcon />
            </Fab>
          </span>
        </Tooltip>
        {selection !== null && (
          <>
            {canEdit && (
              <Tooltip title="Edit Event">
                <Fab size="small" color="secondary" onClick={editEvent}>
                  <EditIcon />
                </Fab>
              </Tooltip>
            )}
            {canReinstate && (
              <Tooltip title="Reinstate Event">
                <Fab size="small" color="secondary" onClick={reinstate}>
                  <OpenInBrowserIcon />
                </Fab>
              </Tooltip>
            )}
            <Tooltip title="Copy Event">
              <Fab size="small" color="secondary" onClick={handleCopyEvent}>
                <FileCopyIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Add attachment">
              <Fab color="secondary" size="small" onClick={addAttachment}>
                <AttachFileIcon />
              </Fab>
            </Tooltip>
            <Tooltip title="Fire RMS export">
              <span>
                <Fab
                  color="secondary"
                  size="small"
                  onClick={fireRMSexport}
                  disabled={!fireRMSActive}>
                  <WhatshotIcon />
                </Fab>
              </span>
            </Tooltip>

            <Tooltip title="Law Enforcement RMS Export">
              <span>
                <Fab
                  disabled={!eventExportToLegacyRMS}
                  color="secondary"
                  size="small"
                  onClick={handleEventExportToLegacy}>
                  <IosShareIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Export Event Data">
              <span>
                <Fab
                  disabled={!isEventExportEnabled}
                  color="secondary"
                  size="small"
                  onClick={handleEventExport}>
                  <SystemUpdateAltIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="Push to legacy RMS">
              <span>
                <Fab
                  color="secondary"
                  size="small"
                  onClick={pushToLegacy}
                  disabled={!pushToLegacyPermission}>
                  <DeviceHubIcon />
                </Fab>
              </span>
            </Tooltip>
            <Tooltip title="email report">
              <span>
                <Fab
                  disabled={selectionStatus !== 'closed' || !mailEventReport}
                  color="secondary"
                  size="small"
                  onClick={handleMailEventReport}>
                  <SendIcon />
                </Fab>
              </span>
            </Tooltip>
          </>
        )}
        <Menu
          anchorEl={viewMenuAnchor}
          keepMounted
          open={Boolean(viewMenuAnchor)}
          onClose={closeDetailsMenu}>
          {typeof selection === 'number' && (
            <MenuItem onClick={viewInternalReport}>Internal</MenuItem>
          )}
          {typeof selection === 'number' && (
            <MenuItem onClick={viewExternalReport}>External</MenuItem>
          )}
          {typeof selection === 'number' && <MenuItem onClick={ripAndRun}>Rip and Run</MenuItem>}
          <MenuItem onClick={handlePrintClick}>List</MenuItem>
          <MenuItem onClick={showShiftReportDialog}>Shift Report</MenuItem>
          <MenuItem onClick={showBatchReportDialog}>Batch Report</MenuItem>
        </Menu>
      </div>
    );
  };

  const onSelection = (selection) => {
    setSelection(selection);
    const item = rows.find((row) => row.id === selection);
    const Status = item ? item.Status.toLowerCase() : null;
    setSelectionStatus(Status);
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Advanced Events</h5>
      {renderSearch()}
      {permissions.ViewDeleted && (
        <Checkbox2
          label="Show Deleted"
          checked={ViewDeleted}
          onChange={(ev, val) => setViewDeleted(val)}
          className={classes.checkbox}
        />
      )}
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={actions()}
        loaded={loaded}
      />
      {/* Implement time search here */}
      <SearchTimePicker
        timeFrom={timeFrom}
        timeTo={timeTo}
        setTimeFrom={setTimeFrom}
        setTimeTo={setTimeTo}
      />

      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Advanced Events"
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        loading={!loaded}
        setSelection={onSelection}
        printFlag={printFlag}
        viewDetails={viewInternalReport}
        refresh={search}
      />
      {shiftRepShown && <DateAndTime close={() => setShiftRepShown(false)} />}
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
  dictionary: state.dictionary,
  isEventExportEnabled: state.config.options.isEventExportEnabled,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  editEvent,
  notify,
  showAddAttachment,
  reinstateEvent,
  pushEvToLegacyRMS,
})(AdvancedEvent);
