import React, { useState, useRef, useCallback, useEffect, Fragment } from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { mapNight, mapDay } from 'config/configureMap';
import { handleError } from 'reducers/ErrorReducer';
import MarkersEvents from './MarkersEvents';
import MarkersUnits from './MarkersUnits';
import EventPanel from './EventPanel';
import UnitPanel from './UnitPanel';
import { updateUnitsResources } from 'reducers/UnitResourcesReducer';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { addEvent } from 'reducers/DialogsReducer';
import { IconButton } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import HomeIcon from '@material-ui/icons/Home';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SelectedUnitMarker from './SelectedUnitMarker';
import { saveUserSetting } from 'reducers/UserSettingsReducer';
import ListItemText from '@material-ui/core/ListItemText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Dictionary from 'components/Dictionary';
import { GoogleMap, Polygon, OverlayView } from '@react-google-maps/api';
import { leaveChannel, joinChannel } from 'reducers/service';
import { getService } from 'reducers/service';
import './map.scss';

const useStyles = makeStyles((theme) => ({
  map: {
    width: '100%',
    height: '100%',
    position: 'relative',
    overflow: 'hidden',
  },
  marker: {
    position: 'sticky',
    minWidth: 20,
    height: 20,
    borderRadius: '50%',
    background: theme.palette.secondary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 3,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
  },
  activeMarker: {
    position: 'sticky',
    minWidth: 22,
    height: 22,
    borderRadius: '50%',
    background: theme.palette.primary.main,
    color: '#fff',
    textAlign: 'center',
    paddingTop: 4,
    boxSizing: 'border-box',
    border: '1px solid #000',
    cursor: 'pointer',
    transform: 'translate(-1px, -1px)',
  },
  addresses: {
    height: 75,
    marginTop: theme.spacing(1),
    overflowY: 'auto',
    overflowX: 'hidden',
    '& > div': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      padding: theme.spacing(0.25, 2),
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: theme.colors.grey4,
      },
    },
  },
  checkbox: {
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiFormControlLabel-root': {
      marginBottom: 0,
    },
    '& .MuiFormControlLabel-label': {
      marginLeft: 5,
    },
  },
  menuIcon: {
    marginLeft: -3,
    marginRight: 10,
    color: theme.colors.grey6,
  },
  agencyIds: {
    display: 'block',
    width: 200,
    margin: '4px 16px',
  },
  label: {
    padding: 0,
    marginLeft: -5,
    marginTop: -6,
    color: 'blue',
    fontWeight: 600,
  },
}));

const mapContainerStyle = {
  width: '100%',
  height: '100%',
};

const geofenceOptions = {
  fillColor: 'blue',
  fillOpacity: 0.4,
  strokeColor: 'blue',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const defaultBounds = { latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 };
const defaultBoundsRef = { latMin: -90, latMax: 90, lngMin: -180, lngMax: 180 };
const defaultCenterRef = { lat: 30.39, lng: -91.07 };
const defaultMenuPos = { x: 100, y: 100 };

function Map(props) {
  const classes = useStyles();
  const { mapOptions, themeMode, units, mapActions, isAuthenticated, userSettings } = props;
  const [menuPos, setMenuPos] = useState(defaultMenuPos);
  const [coords, setCoords] = useState(null);
  const [selectedUnits, setSelectedUnits] = useState([]);
  const [mapBounds, setMapBounds] = useState(defaultBounds);
  const [geofences, setGeofences] = useState([]);
  const [addEventAnchor, setAddEventAnchor] = useState(null);
  const [anchorElShowMore, setAnchorElShowMore] = useState(null);
  const [zoom, setZoom] = useState(16);
  const [unitsCoords, setUnitsCoords] = useState({});
  const coordsServiceRef = useRef(null);
  const mapRef = useRef();
  const zoomRef = useRef(8);
  const centerRef = useRef(defaultCenterRef);
  const menuRef = useRef(null);
  const boundsRef = useRef(defaultBoundsRef);
  const timeoutRef = useRef(0);
  const selectionRef = useRef(null);
  const boundsTimeoutRef = useRef(0);
  const userSettingsRef = useRef(props.userSettings);
  mapOptions.styles = themeMode === 'day' ? mapDay : mapNight;
  const { showESNsOnMap, showZonesOnMap, mapZoneAgency } = userSettings;

  useEffect(() => {
    centerMap();
    props.updateUnitsResources();
    initCoords();
    return () => {
      clearTimeout(timeoutRef);
      clearTimeout(boundsTimeoutRef);
      deactivateCoords();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!units || !selectedUnits.length) return;
    setSelectedUnits(
      selectedUnits.map((unit) => {
        const newUnit = units.find((u) => u.ptsUnitID === unit.ptsUnitID);
        return newUnit || unit;
      })
    );
    // eslint-disable-next-line
  }, [units]);

  useEffect(() => {
    if (!mapActions) return;
    const { type, zoom = 15, lat, lng } = mapActions;
    if (type === 'zoom-and-center') {
      zoomRef.current = zoom;
      centerRef.current = { lat, lng };
      setZoom(zoomRef.current);
      setCoords(centerRef.current);
    }
    // eslint-disable-next-line
  }, [mapActions]);

  useEffect(() => {
    userSettingsRef.current = userSettings;
    updateGeofences();
    // eslint-disable-next-line
  }, [userSettings]);

  const initCoords = async () => {
    coordsServiceRef.current = getService('unit-coordinates');
    try {
      const coords = await coordsServiceRef.current.find();
      setUnitsCoords(coords);
    } catch (err) {
      props.handleError(err);
    }
    joinChannel('unit-coordinates');
    coordsServiceRef.current.on('updated', (coords) => {
      setUnitsCoords(coords);
    });
  };

  const deactivateCoords = () => {
    leaveChannel('unit-coordinates');
    coordsServiceRef.current.off('updated');
  };

  const centerMap = () => {
    const { mapZoom, mapLat, mapLng } = userSettingsRef.current.mapSettings;
    zoomRef.current = mapZoom;
    centerRef.current = { lat: mapLat, lng: mapLng };
    setZoom(zoomRef.current);
    setCoords(centerRef.current);
  };

  const saveMapSettings = () => {
    const zoom = mapRef.current.getZoom();
    const center = mapRef.current.getCenter().toJSON();
    const mapTypeId = mapRef.current.getMapTypeId();
    props.saveUserSetting('mapSettings', {
      mapZoom: zoom,
      mapLat: center.lat,
      mapLng: center.lng,
      mapTypeId,
    });
    closeMoreMenu();
  };

  const showMoreMenu = (ev) => {
    setAnchorElShowMore(ev.currentTarget);
  };

  const closeMoreMenu = () => {
    setAnchorElShowMore(null);
  };

  const renderMoreMenu = () => {
    const handleESNVisibleChange = (ev) => {
      const val = ev.target.checked;
      props.saveUserSetting('showESNsOnMap', val);
      if (val) props.saveUserSetting('showZonesOnMap', false);
      closeMoreMenu();
    };

    const handleZonesVisibleChange = (ev) => {
      const val = ev.target.checked;
      props.saveUserSetting('showZonesOnMap', val);
      if (val) props.saveUserSetting('showESNsOnMap', false);
      if (!val) closeMoreMenu();
    };

    const handleZoneAgencyChange = (ev, val) => {
      props.saveUserSetting('mapZoneAgency', val);
    };

    return (
      <Menu
        id="customized-menu"
        anchorEl={anchorElShowMore}
        keepMounted
        open={Boolean(anchorElShowMore)}
        onClose={closeMoreMenu}>
        <MenuItem onClick={saveMapSettings}>
          <SaveIcon size="small" className={classes.menuIcon} />
          <ListItemText primary="Save Map Position" />
        </MenuItem>
        <MenuItem className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox checked={showESNsOnMap} onChange={handleESNVisibleChange} size="small" />
            }
            label="Show ESNs"
          />
        </MenuItem>
        <MenuItem className={classes.checkbox}>
          <FormControlLabel
            control={
              <Checkbox checked={showZonesOnMap} onChange={handleZonesVisibleChange} size="small" />
            }
            label="Show Zones"
          />
        </MenuItem>
        <Dictionary
          options="Agencies"
          className={classes.agencyIds}
          onChange={handleZoneAgencyChange}
          value={mapZoneAgency}
          label="Agency ID"
          disabled={!showZonesOnMap}
          compact="true"
        />
      </Menu>
    );
  };

  const MapSettings = () => (
    <>
      <IconButton onClick={centerMap} className="map-icon-button">
        <HomeIcon />
      </IconButton>
      <IconButton onClick={showMoreMenu} className="map-icon-button">
        <MoreVertIcon />
      </IconButton>
    </>
  );

  const onCenterChanged = () => {
    if (!mapRef.current) return;
    centerRef.current = mapRef.current.getCenter().toJSON();
  };

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
    mapRef.current.setZoom(zoomRef.current);
    mapRef.current.setMapTypeId(userSettingsRef.current.mapSettings.mapTypeId);
    setZoom(zoomRef.current);
    const controlButtonDiv = document.createElement('div');
    ReactDOM.render(<MapSettings />, controlButtonDiv);
    map.controls[window.google.maps.ControlPosition.TOP_CENTER].push(controlButtonDiv);
  }, []);

  const onMapClick = (ev) => {
    if (selectedUnits.length > 0) {
      setSelectedUnits([]);
      return;
    }
    if (!ev?.pixel?.x) return;
    const screen = {
      x: ev.pixel.x,
      y: ev.pixel.y,
    };
    const coords = {
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    };
    setMenuPos(screen);
    setCoords(coords);
    setAddEventAnchor(menuRef.current);
  };

  const onShapeClick = (ev) => {
    if (selectedUnits.length > 0) {
      setSelectedUnits([]);
      return;
    }
    const screen = {
      x: ev.domEvent.x,
      y: ev.domEvent.y - 60,
    };
    const coords = {
      lat: ev.latLng.lat(),
      lng: ev.latLng.lng(),
    };
    setMenuPos(screen);
    setCoords(coords);
    setAddEventAnchor(menuRef.current);
  };

  const onMapChange = (ev) => {
    clearTimeout(timeoutRef.current);
    if (selectionRef.current) selectionRef.current.style.display = 'none';
    timeoutRef.current = setTimeout(() => {
      updateMapBounds();
      if (selectionRef.current) selectionRef.current.style.display = 'block';
    }, 350);
  };

  const onZoomChanged = () => {
    if (!mapRef.current) return;
    onMapChange();
    zoomRef.current = mapRef.current.getZoom();
    setZoom(zoomRef.current);
  };

  const closeAddEventMenu = () => {
    setAddEventAnchor(null);
  };

  const newEvent = () => {
    closeAddEventMenu();
    props.addEvent({
      Event: {
        CallType: null,
        CallMethod: null,
        RequestedAction: null,
        Description: null,
        lat: coords.lat,
        lng: coords.lng,
      },
    });
  };

  const onUnitClick = (ev, unit) => {
    ev.preventDefault();
    ev.stopPropagation();
    const ctrlKey = ev.ctrlKey;
    updateMapBounds();
    if (ctrlKey) {
      let newSelectedUnits;
      if (selectedUnits.findIndex((u) => u.ptsUnitID === unit.ptsUnitID) !== -1) {
        newSelectedUnits = selectedUnits.filter((u) => u.ptsUnitID !== unit.ptsUnitID);
      } else {
        newSelectedUnits = [...selectedUnits, unit];
      }
      setSelectedUnits(newSelectedUnits);
    } else {
      setSelectedUnits([unit]);
    }

    return false;
  };

  const updateMapBounds = () => {
    if (!mapRef.current) return;
    const bounds = mapRef.current.getBounds();
    if (bounds) {
      const ne = bounds.getNorthEast();
      const sw = bounds.getSouthWest();
      const mapBounds = {
        latMin: sw.lat(),
        latMax: ne.lat(),
        lngMin: sw.lng(),
        lngMax: ne.lng(),
      };
      boundsRef.current = mapBounds;
      setMapBounds(mapBounds);
    } else {
      boundsTimeoutRef.current = setTimeout(() => {
        updateMapBounds();
      }, 1000);
    }
  };

  const renderAddEventMenu = () => (
    <>
      <div style={{ position: 'absolute', top: menuPos.y, left: menuPos.x }} ref={menuRef}></div>
      <Menu
        id="simple-menu"
        anchorEl={addEventAnchor}
        keepMounted
        open={Boolean(addEventAnchor)}
        onClose={closeAddEventMenu}>
        <MenuItem onClick={newEvent}>New Event</MenuItem>
        <MenuItem onClick={closeAddEventMenu}>Close</MenuItem>
      </Menu>
    </>
  );

  const dragStart = () => {
    const dragObj = {
      units: selectedUnits.map((unit) => unit.ptsUnitID),
      ptsEventID: null,
    };
    localStorage.setItem('dragNdrop', JSON.stringify(dragObj));
  };

  const dragEnd = () => {
    localStorage.removeItem('dragNdrop');
    setSelectedUnits([]);
  };

  const updateGeofences = () => {
    const { showESNsOnMap, showZonesOnMap, mapZoneAgency } = userSettings;
    if (showESNsOnMap) {
      setGeofences(
        [...props.esns].map((e) => {
          e.ZoneCode = e.ESN;
          return e;
        })
      );
    } else if (showZonesOnMap) {
      if (mapZoneAgency) {
        setGeofences(props.zones.filter((z) => z.AgencyID === mapZoneAgency.AgencyID));
      } else {
        setGeofences(props.zones);
      }
    } else {
      setGeofences([]);
    }
  };

  const renderGeofences = () => {
    return geofences
      .filter((geofence) => Boolean(geofence.path))
      .map((g) => (
        <Fragment key={g.ptsGeofenceID}>
          <Polygon
            paths={g.path}
            options={{ ...geofenceOptions, fillColor: g.Color, strokeColor: g.Color }}
            onClick={onShapeClick}
          />
          <OverlayView
            mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
            position={g.center}
            onClick={onShapeClick}>
            <div className={classes.label}>{g.ZoneCode}</div>
          </OverlayView>
        </Fragment>
      ));
  };

  const renderMap = () => {
    return (
      <div className={classes.map}>
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          zoom={zoomRef.current}
          center={centerRef.current}
          options={mapOptions}
          key={themeMode}
          onLoad={onMapLoad}
          onZoomChanged={onZoomChanged}
          onCenterChanged={onCenterChanged}
          className={classes.map}
          onClick={onMapClick}
          onDrag={onMapChange}>
          {renderGeofences()}
          <MarkersEvents />
          <MarkersUnits onUnitClick={onUnitClick} coords={unitsCoords} />
        </GoogleMap>
        {renderAddEventMenu()}
        {renderMoreMenu()}
        <div ref={selectionRef}>
          {zoom > 6 &&
            selectedUnits.map((unit) => (
              <SelectedUnitMarker
                unit={unit}
                key={unit.ptsUnitID}
                mapBounds={mapBounds}
                onClick={onUnitClick}
                dragStart={dragStart}
                dragEnd={dragEnd}
                zoom={zoom}
                coords={unitsCoords[unit.ptsUnitID]}
              />
            ))}
        </div>
        <EventPanel />
        <UnitPanel />
      </div>
    );
  };

  return <div className={classes.map}>{isAuthenticated && renderMap()}</div>;
}

const mapStateToProps = (state) => {
  return {
    units: state.units,
    themeMode: state.theme.mode,
    mapOptions: state.map.options,
    mapActions: state.map.action,
    isAuthenticated: state.user.isAuthenticated,
    userSettings: state.userSettings,
    zones: state.zones,
    esns: state.esns,
  };
};

export default connect(mapStateToProps, {
  handleError,
  updateUnitsResources,
  addEvent,
  saveUserSetting,
})(Map);
