import React from 'react';
import SearchMenu, { FilterConsumer } from '../components/SearchMenu';
import AdvancedEvent from './AdvancedEvent';
import { Redirect } from 'react-router-dom';
import { getAgenciesFullPermission } from 'reducers/PermissionsReducer';

function AdvancedEventsSearch() {
  const canReadAgencies = getAgenciesFullPermission('cad', 'Events', 'Read');
  if (!canReadAgencies.length) return <Redirect to="/" />;
  return (
    <SearchMenu agencyList={canReadAgencies}>
      <FilterConsumer>{(AgencyFilter) => <AdvancedEvent filter={AgencyFilter} />}</FilterConsumer>
    </SearchMenu>
  );
}

export default AdvancedEventsSearch;
