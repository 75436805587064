import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { displayDateTime } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import InfoIcon from '@material-ui/icons/Info';
import EditIcon from '@material-ui/icons/Edit';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { showReport } from 'reducers/DialogsReducer';
import { searchNames } from 'reducers/SearchReducer';
import Fab from '@material-ui/core/Fab';
import CheckIcon from '@material-ui/icons/Check';
import { showAddAttachment } from 'reducers/DialogsReducer';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { showEditName, showEditPerson } from 'reducers/DialogsReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import XGrid3 from 'components/XGrid3';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import { Alert } from '@material-ui/lab';
import TextField2 from 'components/TextField2';
import Button from '@material-ui/core/Button';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(2),
    },
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: 8,
  },
  filter: {
    width: '100%',
    marginBottom: '8px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  'PersonID',
  'FullName',
  'FirstName',
  'LastName',
  'NickName',
  'NameType',
  {
    field: 'IsPersonDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Created',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'CreatedBy',
  {
    field: 'Updated',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'UpdatedBy',
  {
    field: 'IsDeleted',
    renderCell: bool,
    format: 'bool',
  },
  {
    field: 'Deleted',
    valueFormatter: (params) => displayDateTime(params.value),
  },
  'DeletedBy',
  'DeletedIP',
];

function Name(props) {
  const classes = useStyles();
  const { dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [loaded, setLoaded] = useState(true);
  const [nameColumns, setNameColumns] = useState(defaultColumns);
  const permissions = getFullPermissions('globals', 'People', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);

  useEffect(() => {
    const type = dataUpdate?.type;
    if (type === 'Name' || type === 'Person') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = async (offset = 0, mergeRows = false) => {
    if (text.length < 3) return;
    setLoaded(false);
    searchNames(text, offset, limit)
      .then((result) => {
        const nextStartingIndex = rows.length;
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);
        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows)
          setRows((prevState) => [...prevState, ...processData(result, nextStartingIndex)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data, nextStartingIndex = 0) => {
    return data.map((row, idx) => {
      return {
        ...row,
        id: idx + nextStartingIndex,
      };
    });
  };

  const viewDetails = () => {
    let item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({ id: 45, data: { ptsPersonID: item.ptsPersonID }, title: 'Person Details' });
  };

  const addAttachment = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showAddAttachment({ id: ptsPersonID, type: 'Person' });
  };

  const editName = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID, ptsNameID } = item;
    if (ptsNameID) {
      props.showEditName(ptsNameID);
    } else {
      props.showEditPerson(ptsPersonID);
    }
  };

  const editPerson = () => {
    const item = rows.find((row) => row.id === selection);
    const { ptsPersonID } = item;
    props.showEditPerson(ptsPersonID);
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };
  if (!permissions.Read) return <Redirect to="/" />;

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  const renderSaveBtn = () => {
    const disabled = text.length < 3;
    return (
      <div>
        <Button onClick={() => search()} variant="outlined" disabled={disabled}>
          Search
        </Button>
      </div>
    );
  };

  return (
    <div>
      <h5>Names</h5>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          {renderSearch()}
          {renderSaveBtn()}
        </div>
        <div className={classes.actions}>
          <Tooltip title="Print list">
            <Fab size="small" color="secondary" onClick={handlePrintClick}>
              <LocalPrintshopRoundedIcon />
            </Fab>
          </Tooltip>
          {selection !== null && (
            <>
              {permissions.Edit && (
                <Tooltip title="Edit name">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editName}>
                    <InsertDriveFileIcon />
                  </Fab>
                </Tooltip>
              )}
              {permissions.Edit && (
                <Tooltip title="Edit person">
                  <Fab className="ml-2" size="small" color="secondary" onClick={editPerson}>
                    <EditIcon />
                  </Fab>
                </Tooltip>
              )}
              <Tooltip title="View details">
                <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
                  <InfoIcon />
                </Fab>
              </Tooltip>
              <Tooltip title="Add attachment">
                <Fab className="ml-2" color="secondary" size="small" onClick={addAttachment}>
                  <AttachFileIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Names"
        columns={nameColumns}
        setColumns={setNameColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  showAddAttachment,
  showEditName,
  showEditPerson,
})(Name);
