import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Tooltip from 'components/Tooltip';
import Button from '@material-ui/core/Button';
import { sortObjArr, areObjEqual, asyncForEach } from 'utils/functions';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { addEventRouting } from 'reducers/EventsReducer';
import Chip from '@material-ui/core/Chip';
import { getRoutingRecommendations } from 'reducers/EsnReducer';
import settings from 'config/settings';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { displayDateTime } from 'reducers/TimeReducer';
import Autocomplete2 from 'components/Autocomplete2';

const useStyles = makeStyles((theme) => ({
  routings: {
    maxHeight: settings.maxTabHeight,
    overflowY: 'auto',
  },
  EventRouting: {
    textAlign: 'left',
  },
  route: {
    padding: '10px 15px 5px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  routing: {
    padding: '10px 15px 5px',
    position: 'relative',
    '& p': {
      fontSize: 13,
      marginBottom: 5,
    },
    borderBottom: `1px solid ${theme.card.hr}`,
    '&:hover': {
      '& $rowActions': {
        opacity: 1,
      },
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h4': {
      fontSize: '1em',
      marginBottom: '0.25em',
    },
  },
  actions: {
    padding: '5px 15px',
    textAlign: 'right',
    borderBottom: `1px solid ${theme.card.hr}`,
    '& label': {
      marginBottom: 0,
    },
  },
  rowActions: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(3) + 2,
    opacity: 0,
    transition: 'opacity 0.3s',
  },
  selectWrap: {
    padding: '10px 15px 5px',
    width: '100%',
    '& strong': {
      fontWeight: 500,
    },
  },
  recommendations: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0.5, 2),
    margin: 0,
  },
  chip: {
    margin: theme.spacing(0.5),
  },
}));

function RoutingTab(props) {
  const classes = useStyles();
  const perms = getFullPermissions('cad', 'Events', 'any');
  const { sortOption, ptsEventID, dictionary, disableEdit } = props;
  const [data, setData] = useState([]);
  const [availAgencies, setAvailAgencies] = useState([]);
  const [edited, setEdited] = useState(perms.Create ? '' : null);
  const [routingRecommendation, setRoutingRecommendation] = useState([]);

  useEffect(() => {
    const { Agencies } = dictionary;
    if (!props.data) {
      setAvailAgencies(Agencies);
      return;
    }
    const newData = props.data.map((route) => {
      const agency = Agencies.find((a) => a.AgencyID === route.AgencyId);
      const Description = agency ? agency.Description : '';
      return {
        Code: route.AgencyId,
        Description,
        CreatedDate: route.Created,
        CreatedBy: route.CreatedBy,
      };
    });
    setData(sortObjArr(newData, 'Code', sortOption));
    const newAvailAgencies = Agencies.filter((a) => !newData.find((d) => d.Code === a.AgencyID));
    if (!areObjEqual(newAvailAgencies, availAgencies)) {
      setAvailAgencies(newAvailAgencies);
    }
    // eslint-disable-next-line
  }, [props.data, sortOption]);

  useEffect(() => {
    getRoutingRecommendation();
    // eslint-disable-next-line
  }, [availAgencies]);

  const handleDelete = (AgencyID) => () => {
    setRoutingRecommendation(routingRecommendation.filter((id) => id !== AgencyID));
  };

  const renderRecommendation = () => {
    return (
      <div className={classes.recommendations}>
        <div>
          {routingRecommendation.map((AgencyID) => (
            <Chip
              key={AgencyID}
              label={AgencyID}
              onDelete={handleDelete(AgencyID)}
              className={classes.chip}
              size="small"
            />
          ))}
        </div>
        <div>
          {perms.Created && (
            <Button size="small" onClick={addRecommended}>
              Add Recommended
            </Button>
          )}
        </div>
      </div>
    );
  };

  const addRecommended = async () => {
    props.showSpinner();
    let errors = false;
    let recommendation = [...routingRecommendation];
    await asyncForEach(routingRecommendation, async (AgencyID) => {
      await addEventRouting(AgencyID, ptsEventID)
        .then(() => {
          recommendation = recommendation.filter((el) => el !== AgencyID);
        })
        .catch((err) => {
          props.handleError(err, `Error, routing ${AgencyID} not created`);
          errors = true;
        });
    });
    setRoutingRecommendation(recommendation);
    props.notifyDataUpdate({ type: 'event', data: ptsEventID });
    //if (!errors) props.notify('Routings created', 'success');
    props.hideSpinner();
  };

  const getRoutingRecommendation = async () => {
    const currentAgencies = data.map((a) => a.Code);
    props.showSpinner();
    try {
      const recommendations = await getRoutingRecommendations(ptsEventID, dictionary);
      const recAgencies = recommendations
        .map((r) => r.AgencyID)
        .filter((r) => currentAgencies.indexOf(r) === -1);
      setRoutingRecommendation(recAgencies);
    } catch (err) {
      props.handleError(err, 'Error receiving routing recommendations.');
    }
    props.hideSpinner();
  };

  const edit = () => {
    setEdited('');
  };

  const closeEdit = () => {
    setEdited(null);
  };

  const selectAgency = (ev, val) => {
    setEdited(val);
  };

  const onSave = () => {
    const { AgencyID } = edited;
    addEventRouting(AgencyID, ptsEventID)
      .then(() => {
        //props.notify('Routing created', 'success');
        props.notifyDataUpdate({ type: 'event', data: ptsEventID });
      })
      .catch((err) => props.handleError(err, `Error, routing ${edited} not created`));
    setEdited('');
  };

  return (
    <div className={classes.routings}>
      {!disableEdit && (
        <>
          {routingRecommendation.length > 0 && renderRecommendation()}
          <div className={classes.actions}>
            {edited === null && availAgencies.length !== 0 && (
              <Tooltip title="Add event routing">
                <span>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={edit}
                    disabled={availAgencies.length === 0 || !perms.Edit}>
                    <AddIcon />
                  </IconButton>
                </span>
              </Tooltip>
            )}
            {edited === null && availAgencies.length === 0 && (
              <IconButton color="primary" size="small" disabled>
                <AddIcon />
              </IconButton>
            )}
            {edited !== null && (
              <>
                <Tooltip title="Save note">
                  <span>
                    <IconButton
                      color="primary"
                      size="small"
                      onClick={onSave}
                      disabled={!edited || !perms.Edit}>
                      <SaveIcon />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Discard changes">
                  <IconButton color="primary" size="small" onClick={closeEdit}>
                    <CloseIcon />
                  </IconButton>
                </Tooltip>
              </>
            )}
          </div>
          {edited !== null && (
            <div className={classes.selectWrap}>
              <FormControl style={{ width: '100%' }} size="small">
                <Autocomplete2
                  options={availAgencies}
                  className={classes.item}
                  onChange={selectAgency}
                  value={edited}
                  label=" Agencies"
                  compact="true"
                  getOptionSelected={(option, value) => option.AgencyID === value.AgencyID}
                  getOptionLabel={(option) => (option.AgencyID ? option.AgencyID : '')}
                  renderOption={(option) => (
                    <span>
                      {option.AgencyID} - {option.Description}
                    </span>
                  )}
                />
              </FormControl>
            </div>
          )}
        </>
      )}
      {data.map(({ Code, Description, CreatedBy, CreatedDate }) => {
        return (
          <div className={classes.route} key={Code}>
            <div className={classes.header}>
              <h4>{Code}</h4>
              <div>
                <h4>{CreatedBy}</h4>
                <span>{displayDateTime(CreatedDate)}</span>
              </div>
            </div>
            <p>{Description}</p>
          </div>
        );
      })}
    </div>
  );
}

export default connect(null, {
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  notifyDataUpdate,
})(RoutingTab);
