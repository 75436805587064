import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { closeReport } from 'reducers/DialogsReducer';
import Dialog from 'components/Dialog';
import { getReport } from 'reducers/ReportsReducer';
import { handleError } from 'reducers/ErrorReducer';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
  dialog: {
    '& .MuiDialogContent-root': {
      padding: 0,
      '& > div': {
        padding: 0,
      }
    }
  },
  reportWrap: {
    maxWidth: '100%',
    width: 1500,
    minHeight: 'calc(100vh - 140px)',
    '& > object': {
      width: '100%',
      border: 'none',
      height: 'calc(100vh - 140px)',
    }
  },
  loader: {
    maxWidth: '100%',
    width: 1500,
    minHeight: 'calc(100vh - 140px)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

function ExampleWindow(props) {
  const classes = useStyles();
  const { report } = props;
  const [reportData, setReportData] = useState('');
  const [title, setTitle] = useState('Report Details');
  const mountedRef = useRef(true);

  useEffect(() => {
    if (!report) return;
    const { id, data, title } = report;
    if (!id || !data) return;
    getReportData(id, data);
    if (title) setTitle(title);
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, [report]);


  const getReportData = async (id, data) => {
    try {
      const reportData = await getReport(id, data);
      if (!mountedRef.current) return;
      setReportData(reportData);
    } catch (err) {
      props.handleError(err);
    }
  }

  const close = () => {
    props.closeReport();
  };

  const renderReport = () => (
    <div className={classes.reportWrap}>
      <object data={`data:application/pdf;base64,${reportData}`} type="application/pdf"></object>
    </div>
  );

  const renderLoader = () => (
    <div className={classes.loader}>
      <CircularProgress />
    </div>
  );

  return (
    <Dialog toolbar onClose={close} title={title} className={classes.dialog} maxWidth="xl">
      {Boolean(reportData) ? renderReport() : renderLoader()}
    </Dialog>
  );
}

export default connect(null, {
  closeReport, handleError
})(ExampleWindow);
