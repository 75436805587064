import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import Autocomplete2 from 'components/Autocomplete2';
import TextField2 from 'components/TextField2';
import DatePicker2 from 'components/DatePicker2';
import formStyles, { gridStyle, Row, ColCard, Fills } from 'utils/formStyles';
import { notify } from 'reducers/NotifierReducer';
import { getService } from 'reducers/service';
import { getAddressCoordinates, getPtsCoordinateID } from 'reducers/AddressReducer';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getCoords, parseCoords } from 'utils/mapFunctions';
import Dictionary from 'components/Dictionary';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
}));

const AddressCoordinates = (props) => {
  const classes = useStyles();
  const { dictionary, ptsAddressID } = props;
  const { Measures, Datum } = dictionary;
  const [uncertaintyDistance, setUncertaintyDistance] = useState('');
  const [datum, setDatum] = useState(null);
  const [coorDt, setCoorDt] = useState(null);
  const [lat, setLat] = useState('');
  const [lng, setLng] = useState('');
  const [altitude, setAltitude] = useState('');
  const [pointOfReference, setPointOfReference] = useState('');
  const [cellID, setCellID] = useState('');
  const [sectorID, setSectorID] = useState('');
  const [speed, setSpeed] = useState('');
  const [altitudeUnitOfMeasure, setAltitudeUnitOfMeasure] = useState(null);
  const [speedUnitOfMeasure, setSpeedUnitOfMeasure] = useState(null);
  const [data, setData] = useState(null);
  const [coorID, setCoorID] = useState(null);

  useEffect(() => {
    getCoordinatesData();
  }, []);

  useEffect(() => {
    if (!data) return;
    setUncertaintyDistance(data.UnCertaintyDistance || '');
    if (data.Datum) setDatum(Datum.find((m) => m.Code === data.Datum));
    setCoorDt(data.CoordinateDT || null);
    setAltitude(data.Altitude || '');
    setPointOfReference(data.AltitudeRefPoint || '');
    setCellID(data.CellIDText || '');
    setSectorID(data.CellSectorIDText || '');
    setSpeed(data.Speed || '');
    if (data.AltitudeUnitMeasure)
      setAltitudeUnitOfMeasure(Measures.find((m) => m.Code === data.AltitudeUnitMeasure));
    if (data.SpeedUnitMeasure)
      setSpeedUnitOfMeasure(Measures.find((m) => m.Code === data.SpeedUnitMeasure));
    const { lat, lng } = getCoords(data);
    setLat(lat || '');
    setLng(lng || '');
  }, [data]);

  const getCoordinatesData = async () => {
    const coorID = await getPtsCoordinateID(ptsAddressID);
    const { ptsCoordinateID } = coorID;
    if (!ptsCoordinateID) return;
    setCoorID(ptsCoordinateID);
    const coorData = await getAddressCoordinates(ptsCoordinateID);
    setData(coorData);
    const { lat, lng } = getCoords(coorData);
    setLat(lat || '');
    setLng(lng || '');
  };

  const handleSave = () => {
    const coordinate = getCoordinateObj();
    coordinate.ptsCoordinateID = coorID;
    const service = getService('cad-address-coordinates');
    service
      .create(coordinate)
      .then((res) => {
        const { ptsCoordinateID } = res;
        const service = getService('cad-address-coordinates');
        service.patch(ptsAddressID, { ptsCoordinateID });
      })
      .then(() => {
        //props.notify('Coordinate saved', 'success');
        getCoordinatesData();
      })
      .catch((err) => props.handleError(err, 'Error saving coordinate'));
  };

  const getCoordinateObj = () => {
    const { LatitudeSign, LatitudeDegree, LongitudeSign, LongitudeDegree } = parseCoords({
      lat,
      lng,
    });
    return {
      LatitudeSign,
      LatitudeDegree,
      LongitudeSign,
      LongitudeDegree,
      UnCertaintyDistance: uncertaintyDistance || null,
      Datum: datum?.Code || null,
      CoordinateDT: coorDt || null,
      Altitude: altitude || null,
      AltitudeRefPoint: pointOfReference || null,
      CellIDText: cellID || null,
      CellSectorIDText: sectorID || null,
      Speed: speed || null,
      AltitudeUnitMeasure: altitudeUnitOfMeasure?.Code || null,
      SpeedUnitMeasure: speedUnitOfMeasure?.Code || null,
    };
  };

  const renderLat = () => {
    const onChange = (ev, val) => setLat(val);
    return (
      <TextField2
        className={classes.item}
        label="Latitude"
        value={lat}
        onChange={onChange}
        type="number"
        min={-90}
        max={90}
      />
    );
  };

  const renderLng = () => {
    const onChange = (ev, val) => setLng(val);
    return (
      <TextField2
        className={classes.item}
        label="Longitude"
        value={lng}
        onChange={onChange}
        type="number"
        min={-180}
        max={180}
      />
    );
  };

  const renderUncertaintyDistance = () => {
    const onChange = (ev, val) => setUncertaintyDistance(val);
    return (
      <TextField2
        className={classes.item}
        label="Uncertainty Distance"
        value={uncertaintyDistance}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderDatum = () => {
    const onChange = (ev, val) => setDatum(val);
    return (
      // <Autocomplete2
      //   className={classes.item}
      //   options={datumOptions}
      //   value={datum}
      //   onChange={onChange}
      //   label="Datum"
      //   getOptionSelected={(option, value) => option.Code === value.Code}
      //   getOptionLabel={(option) => (option.Code ? option.Code : '')}
      //   renderOption={(option) => <span>{option.Code} - {option.Description}</span>}
      // />
      <Dictionary
        options="Datum"
        className={classes.item}
        onChange={onChange}
        value={datum}
        label="Datum"
      />
    );
  };

  const renderCoordinateDateTime = () => {
    return (
      <DatePicker2
        onChange={(date) => setCoorDt(date)}
        label="Coor DT"
        value={coorDt}
        className={classes.item}
      />
    );
  };

  const renderAltitude = () => {
    const onChange = (ev, val) => setAltitude(val);
    return (
      <TextField2
        className={classes.item}
        label="Altitude"
        value={altitude}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderPointOfReference = () => {
    const onChange = (ev, val) => setPointOfReference(val);
    return (
      <TextField2
        className={classes.item}
        label="Point Of Reference"
        value={pointOfReference}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderCellID = () => {
    const onChange = (ev) => setCellID(ev.target.value);
    return (
      <TextField2
        className={classes.item}
        label="CellID"
        value={cellID}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderSectorID = () => {
    const onChange = (ev) => setSectorID(ev.target.value);
    return (
      <TextField2
        className={classes.item}
        label="SectorID"
        value={sectorID}
        onChange={onChange}
        max={2000}
      />
    );
  };

  const renderSpeed = () => {
    const onChange = (ev) => setSpeed(ev.target.value);
    return (
      <TextField2
        className={classes.item}
        label="Speed"
        value={speed}
        onChange={onChange}
        type="number"
        min={0}
      />
    );
  };

  const renderAltitudeUnitOfMeasure = () => {
    const onChange = (ev, val) => setAltitudeUnitOfMeasure(val);
    return (
      <Dictionary
        options="Measures"
        className={classes.item}
        onChange={onChange}
        value={altitudeUnitOfMeasure}
        label="Altitude Units"
      />
    );
  };
  const renderSpeedUnitOfMeasure = () => {
    const onChange = (ev, val) => setSpeedUnitOfMeasure(val);
    return (
      <Dictionary
        options="Measures"
        className={classes.item}
        onChange={onChange}
        value={speedUnitOfMeasure}
        label="Speed Units"
      />
    );
  };

  return (
    <div className={classes.wrap}>
      <Row>
        <ColCard minWidth={500}>
          <FormDivider title="Coordinates" />
          <div className={classes.row}>
            {renderLat()}
            {renderLng()}
            {renderUncertaintyDistance()}
            {renderDatum()}
            {renderCoordinateDateTime()}
            <Fills className={classes.item} />
          </div>
        </ColCard>
        <ColCard minWidth={500}>
          <FormDivider title="Geographical Details" />
          <div className={classes.row}>
            {renderAltitude()}
            {renderPointOfReference()}
            {renderAltitudeUnitOfMeasure()}
            {renderCellID()}
            {renderSectorID()}
            {renderSpeed()}
            {renderSpeedUnitOfMeasure()}
            <Fills className={classes.item} />
          </div>
        </ColCard>
      </Row>
      <Button
        className={classes.btn}
        color="primary"
        variant="contained"
        autoFocus
        onClick={handleSave}>
        <SaveIcon /> Save
      </Button>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError,
  notify,
})(AddressCoordinates);
