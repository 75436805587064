import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { displayDateTime, getTimeFromDate } from 'reducers/TimeReducer';
import { handleError } from 'reducers/ErrorReducer';
import { makeStyles } from '@material-ui/core/styles';
//import SearchDateTimePicker from '../components/SearchDateTimePicker';
import SearchDatePicker from '../components/SearchDatePicker2';
import { searchUnitStatus, removeUnitStatus } from 'reducers/SearchReducer';
import TextField2 from 'components/TextField2';
import { showReport } from 'reducers/DialogsReducer';
import Autocomplete2 from 'components/Autocomplete2';
import { gridStyle } from 'utils/formStyles';
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import { editUnitAction } from 'reducers/DialogsReducer';
import DeleteIcon from '@material-ui/icons/Delete';
import { notify } from 'reducers/NotifierReducer';
import XGrid3 from 'components/XGrid3';
import { getFullPermissions } from 'reducers/PermissionsReducer';
import { Redirect } from 'react-router-dom';
import LocalPrintshopRoundedIcon from '@material-ui/icons/LocalPrintshopRounded';
import InfoIcon from '@material-ui/icons/Info';
import { getService } from 'reducers/service';
import { sortObjArr } from 'utils/functions';
import { Alert } from '@material-ui/lab';
import SearchTimePicker from 'Search/components/SearchTimePicker';
import RecordLimitAlert from 'Search/components/RecordLimitAlert';
const useStyles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    alignItems: 'flex-start',
    marginTop: theme.spacing(2),
    '& > div': {
      marginRight: theme.spacing(1.5),
    },
  },
  unit: gridStyle(250, 250),
  quickFilter: gridStyle(200, 370),
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  panel: {
    '& .MuiDataGridPanelFooter-root': {
      display: 'none',
    },
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  search: {
    width: 450,
    marginRight: 8,
  },
}));

const defaultColumns = [
  'Mobile',
  'EventID',
  'Unit',
  'Names',
  'Action',
  {
    field: 'Occurred',
    headerName: 'Occurred',
    width: 170,
    valueFormatter: (params) => displayDateTime(params.value),
    hide: false,
  },
  'AgencyID',
  'Division',
  'Zone',
  'Location',
  'TripNumber',
  'Resources',
  'Created',
  'CreatedBy',
  'Updated',
  'UpdatedBy',
  'IsDeleted',
  'Is Deleted',
  'DeletedBy',
  'DeletedIP',
];

function UnitStatusHistory(props) {
  const classes = useStyles();
  const { filter, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTo, setDateTo] = useState(new Date());
  const [loaded, setLoaded] = useState(true);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);
  const [units, setUnits] = useState([]);
  const [unit, setUnit] = useState(null);
  const query = new URLSearchParams(useLocation().search);
  const permissions = getFullPermissions('cad', 'Unit Status', 'any');
  const [printFlag, setPrintFlag] = useState(false);
  const [text, setText] = useState('');
  //const showAlert = rows.length >= 1000;
  const [timeFrom, setTimeFrom] = useState(null);
  const [timeTo, setTimeTo] = useState(null);
  const [rowCount, setRowCount] = useState(0);
  const limit = 1000;
  const [page, setPage] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  useEffect(() => {
    const defaultUnit = query.get('u');
    getAllUnits()
      .then((units) => {
        setUnits(sortObjArr(units, 'Code'));
        if (defaultUnit) {
          const unit = units.find((unit) => unit.Code === defaultUnit);
          unit && setUnit(unit);
        }
      })
      .catch(props.handleError);
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === 'unit-status') search();
    // eslint-disable-next-line
  }, [dataUpdate]);

  useEffect(() => {
    if (rows.length === rowCount) setShowAlert(false);
    else if (rows.length >= limit) setShowAlert(true);
  }, [rows, rowCount]);

  const search = (offset = 0, mergeRows = false) => {
    const unitCode = unit ? unit.Code : null;
    const startTime = timeFrom ? getTimeFromDate(timeFrom) : timeFrom;
    const endTime = timeFrom ? getTimeFromDate(timeTo) : timeTo;
    setLoaded(false);
    searchUnitStatus(text, dateFrom, dateTo, startTime, endTime, filter, unitCode, offset, limit)
      .then((result) => {
        //adding total number of rows
        result.length ? setRowCount(result[0].Count) : setRowCount(0);

        //TRUE means, fetching next 1000 rows
        //so merging new rows with existing
        if (mergeRows) setRows((prevState) => [...prevState, ...processData(result)]);
        else {
          setRows(processData(result));
          setPage(0); //resetting page
        }
      })
      .catch(props.handleError)
      .finally(() => setLoaded(true));
  };

  const processData = (data) => {
    return data.map((row) => {
      return { ...row, id: row.ptsActionID };
    });
  };

  const getAllUnits = async () => {
    const service = getService('cadunit');
    const result = await service.find({
      query: {
        $limit: 1000,
      },
    });
    let units = result && result.data ? result.data : [];
    units = units.map((unit) => ({
      Code: unit.Unit,
      Description: unit.AgencyID + (unit.IsDeleted ? ' (DEL)' : ''),
    }));
    return units;
  };

  const viewDetails = () => {
    const item = rows.find((row) => row.id === selection);
    if (!item) return;
    props.showReport({
      id: 38,
      data: { ptsActionID: item.ptsActionID },
      title: 'Unit Action Details',
    });
  };

  const editStatus = () => {
    const item = rows.find((row) => row.id === selection);
    props.editUnitAction(item.ptsActionID);
  };

  const deleteStatus = async () => {
    if (!window.confirm('Are you sure you want to delete this status?')) return;
    try {
      await removeUnitStatus(selection);
      search();
      //props.notify('Unit Status Deleted', 'success');
    } catch (e) {
      props.handleError(e);
    }
  };

  const handlePrintClick = () => {
    setPrintFlag(true);
    setTimeout(() => setPrintFlag(false), 1000);
  };

  const onPageChange = () => {
    const offset = (page + 1) * limit;
    const mergingRows = true;
    search(offset, mergingRows);
    setPage(page + 1);
  };
  const actions = () => {
    return (
      <div className={classes.actions}>
        <Tooltip title="Print list">
          <Fab size="small" color="secondary" onClick={handlePrintClick}>
            <LocalPrintshopRoundedIcon />
          </Fab>
        </Tooltip>
        {selection !== null && (
          <Tooltip title="View details">
            <Fab className="ml-2" size="small" color="secondary" onClick={viewDetails}>
              <InfoIcon />
            </Fab>
          </Tooltip>
        )}
        {selection !== null && permissions.Edit && (
          <Tooltip title="Edit Unit Status">
            <Fab className="ml-2" size="small" color="secondary" onClick={editStatus}>
              <EditIcon />
            </Fab>
          </Tooltip>
        )}
        {selection !== null && permissions.Delete && (
          <Tooltip title="Delete">
            <Fab className="ml-2" size="small" color="secondary" onClick={deleteStatus}>
              <DeleteIcon />
            </Fab>
          </Tooltip>
        )}
      </div>
    );
  };

  if (!permissions.Read) return <Redirect to="/" />;

  const renderSearch = () => {
    const onChange = (ev, val) => setText(val);
    const onKeyDown = (ev) => ev.key === 'Enter' && search();
    return (
      <TextField2
        value={text}
        onChange={onChange}
        type="search"
        className={classes.search}
        onKeyDown={onKeyDown}
        compact="true"
      />
    );
  };

  return (
    <div>
      <h5>Unit Status</h5>
      <div className={classes.filters}>
        {renderSearch()}
        <Autocomplete2
          options={units}
          className={classes.unit}
          onChange={(ev, val) => setUnit(val)}
          value={unit}
          label="Unit"
          compact="true"
        />
      </div>
      <SearchDatePicker
        search={search}
        dateFrom={dateFrom}
        dateTo={dateTo}
        setDateFrom={setDateFrom}
        setDateTo={setDateTo}
        actions={actions()}
        loaded={loaded}
      />
      {/* Implement time search here */}
      <SearchTimePicker
        timeFrom={timeFrom}
        timeTo={timeTo}
        setTimeFrom={setTimeFrom}
        setTimeTo={setTimeTo}
      />
      {showAlert && (
        <RecordLimitAlert
          handleNextPage={onPageChange}
          rowCount={rowCount}
          currentRows={rows.length}
        />
      )}
      <XGrid3
        name="Unit Status"
        columns={columns}
        setColumns={setColumns}
        rows={rows}
        loading={!loaded}
        setSelection={setSelection}
        printFlag={printFlag}
        viewDetails={viewDetails}
        refresh={search}
      />
    </div>
  );
}

const mapStateToProps = (state) => ({
  units: state.units,
  dataUpdate: state.dataUpdate,
});

export default connect(mapStateToProps, {
  handleError,
  showReport,
  notify,
  editUnitAction,
})(UnitStatusHistory);
