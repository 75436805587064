import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ContainerCard from './ContainerCard';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import AddressLookup2 from 'components/AddressLookup2';
import { veryfySOP, getPlaceName } from 'reducers/EventsReducer';
import { locationEmptyData } from './eventEmptyData';
import Fab from '@material-ui/core/Fab';
import Dictionary from 'components/Dictionary';
import TextField2 from 'components/TextField2';
import { gridStyle, Fills, validatePostcode } from 'utils/formStyles';
import { Tooltip } from '@mui/material';
const useStyles = makeStyles((theme) => ({
  form: {
    margin: '0 -4px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  textField: {
    margin: '0 4px 8px',
  },
  search: {},
  checkbox: {
    margin: '-4px 4px 0',
  },
  w180: {
    maxWidth: '100%',
    flexBasis: 180,
    minWidth: 180,
    flexGrow: 1,
  },
  item: gridStyle(180, 320),
  item100pr: gridStyle('100%', '100%'),
  longField: gridStyle(300, '100%'),
  w556: {
    maxWidth: '100%',
    flexBasis: 556,
    minWidth: 556,
    flexGrow: 1,
  },
  w120x180: {
    maxWidth: 180,
    flexBasis: 120,
    minWidth: 120,
    flexGrow: 1,
  },
  w100pr: {
    width: '100%',
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  AddressLookup: {
    margin: '0 4px 8px',
  },
  actions: {
    padding: theme.spacing(1.5, 0),
    '& button': {
      marginLeft: theme.spacing(1.5),
    },
  },
}));

const compareObj = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2);

export default function LocationForm(props) {
  const classes = useStyles();
  const {
    dictionary,
    no,
    addPlace,
    updateData,
    data,
    title,
    disableEdit,
    locationDescription,
    editingForm = false,
  } = props;

  const { ptsLocationAddressID } = data;
  const [AddressNumber, setAddressNumber] = useState('');
  const [PreDirection, setPreDirection] = useState(null);
  const [StreetName, setStreetName] = useState('');
  const [StreetType, setStreetType] = useState(null);
  const [PostDirection, setPostDirection] = useState(null);
  const [UnitType, setUnitType] = useState(null);
  const [UnitIdentifier, setUnitIdentifier] = useState('');
  const [City, setCity] = useState(null);
  const [State, setState] = useState(null);
  const [PostalCode, setPostalCode] = useState('');
  const [County, setCounty] = useState(null);
  const [Notes, setNotes] = useState('');
  const [IsPrimary, setIsPrimary] = useState(true);
  const [ptsAddressID, setPtsAddressID] = useState(null);
  const [newPlace, setNewPlace] = useState(null);
  const [validForm, setValidForm] = useState(false);
  const [placeSopsAvailable, setPlaceSopsAvailable] = useState(false);
  const [addressSopsAvailable, setAddressSopsAvailable] = useState(false);
  const [ptsCoordinateID, setPtsCoordinateID] = useState(null);
  const [changed, setChanged] = useState(false);
  const [placeChanged, setPlaceChanged] = useState(false);
  const [CrossStreet, setCrossStreet] = useState('');

  const sopsAvailable = placeSopsAvailable || addressSopsAvailable;
  const prevData = useRef(null);
  const mountedRef = useRef(true);
  const focusRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (focusRef?.current) focusRef.current.focus();
    }, 0);
    return () => {
      mountedRef.current = false;
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (compareObj(data, prevData.current)) return;
    prevData.current = data;
    formatInputData();
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (!updateData) return;
    updateData(formatOutputData(), no);
    if (validateForm() && !validForm) setValidForm(true);
    if (!validateForm() && validForm) setValidForm(false);
    // eslint-disable-next-line
  }, [
    AddressNumber,
    PreDirection,
    StreetName,
    StreetType,
    PostDirection,
    City,
    State,
    PostalCode,
    County,
    IsPrimary,
    ptsAddressID,
    UnitIdentifier,
    UnitType,
    Notes,
    CrossStreet,
  ]);

  useEffect(() => {
    if (!placeChanged) return;
    if (changed) setChanged(false);
    // eslint-disable-next-line
  }, [placeChanged]);

  useEffect(() => {
    // remove address id if form changed
    if (!changed) return;
    if (placeChanged) {
      setNewPlace(null);
      setPtsAddressID(null);
      setPtsCoordinateID(null);
    }
    // eslint-disable-next-line
  }, [changed]);

  useEffect(() => {
    props.setStreetName && props.setStreetName(StreetName);
  }, [StreetName]);

  useEffect(() => {
    if (!ptsAddressID) return;
    veryfySOP('Address', ptsAddressID, null).then(setAddressSopsAvailable).catch(console.log);
  }, [ptsAddressID]);

  const formatInputData = () => {
    const {
      AddressNumber,
      PreDirection,
      StreetName,
      StreetType,
      ptsAddressID,
      PlaceName,
      PostDirection,
      ptsCityID,
      State,
      PostalCode,
      County,
      PostalCodeExtension,
      IsPrimary,
      ptsLocationID,
      ptsCoordinateID,
      UnitType,
      UnitIdentifier,
      Notes,
      CrossStreet,
    } = data;
    setPtsAddressID(ptsAddressID);
    setAddressNumber(AddressNumber ? AddressNumber : '');
    if (PreDirection) setPreDirection(getDataObj('StreetDirections', PreDirection));
    setStreetName(StreetName ? StreetName : '');
    if (StreetType) setStreetType(getDataObj('StreetTypes', StreetType));
    if (PostDirection) setPostDirection(getDataObj('StreetDirections', PostDirection));
    if (State) setState(getDataObj('States', State));
    renderPostalInput(PostalCode, PostalCodeExtension);
    if (County) setCounty(getDataObj('Counties', County));
    setPtsCoordinateID(ptsCoordinateID);
    setIsPrimary(!!IsPrimary);
    if (UnitType) setUnitType(getDataObj('AddressUnitTypes', UnitType));
    setUnitIdentifier(UnitIdentifier ? UnitIdentifier : '');
    setNotes(Notes ? Notes : '');
    setCrossStreet(CrossStreet ? CrossStreet : '');
    if (ptsCityID) {
      const cityObj = dictionary.Cities.find((obj) => obj.ptsCityID === ptsCityID);
      const cityCode = cityObj ? cityObj.Code : '';
      setCity({ Code: cityCode, ptsCityID });
    }
    if (ptsAddressID) {
      setNewPlace(data);
      if (ptsLocationID && !PlaceName) getLocationPlaceName(ptsLocationID, data);
    } else {
      setNewPlace(null);
    }
  };

  const renderPostalInput = (code, ext) => {
    if (!code) return;
    if (!ext) setPostalCode(code);
    else setPostalCode(`${code} - ${ext}`);
  };

  const getLocationPlaceName = (ptsLocationID, data) => {
    getPlaceName(ptsLocationID)
      .then((result) => {
        if (!mountedRef.current) return;
        const { Name } = result;
        if (Name) {
          setNewPlace({ ...data, PlaceName: Name });
        }
      })
      .catch((e) => console.log(e));
  };

  const getDataObj = (type, Code) => {
    const obj = dictionary[type].find((obj) => obj.Code === Code);
    return obj ? obj : null;
  };

  const onPostcodeChange = (ev, val) => {
    !changed && setChanged(true);
    setPostalCode(val);
  };

  const onAddresNoChange = (ev) => {
    !changed && setChanged(true);
    const value = ev.target.value;
    const reg = /^\d+$/;
    if (value === '' || (value.length < 7 && value.match(reg))) setAddressNumber(value);
  };

  const onStreetNameChange = (ev) => {
    !changed && setChanged(true);
    const value = ev.target.value;
    if (value === '' || value.length < 100) {
      setStreetName(value);
    }
  };

  const onUnitTypeChange = (ev, val) => {
    setUnitType(val);
    !changed && setChanged(true);
  };

  const onUnitChange = (ev) => {
    !changed && setChanged(true);
    const value = ev.target.value;
    if (value === '' || value.length < 500) setUnitIdentifier(value);
  };

  const onCountyChange = (ev, val) => {
    !changed && setChanged(true);
    setCounty(val);
  };

  const onNotesChange = (ev, val) => {
    !changed && setChanged(true);
    setNotes(val);
  };

  const onCrossStreetChange = (ev, val) => {
    !changed && setChanged(true);
    setCrossStreet(val);
  };

  const formatOutputData = () => {
    return {
      AddressNumber: AddressNumber !== '' ? AddressNumber : null,
      PreDirection: PreDirection ? PreDirection.Code : null,
      StreetName: StreetName ? StreetName : null,
      StreetType: StreetType ? StreetType.Code : null,
      PostDirection: PostDirection ? PostDirection.Code : null,
      UnitIdentifier: UnitIdentifier ? UnitIdentifier : null,
      UnitType: UnitType ? UnitType.Code : null,
      ptsCityID: City ? City.ptsCityID : null,
      State: State ? State.Code : null,
      PostalCode: PostalCode ? PostalCode.substr(0, 5) : null,
      PostalCodeExtension: PostalCode.length === 12 ? PostalCode.substr(8, 4) : null,
      County: County ? County.Code : null,
      IsPrimary,
      ptsLocationAddressID,
      ptsAddressID,
      ptsPlaceID: newPlace ? newPlace.ptsPlaceID : null,
      ptsCoordinateID,
      Notes: Notes ? Notes : null,
      CrossStreet: CrossStreet ? CrossStreet : null,
    };
  };

  const clear = (ev) => {
    ev.stopPropagation();
    props.clear();
    setChanged(false);
  };

  const save = (ev) => {
    ev.stopPropagation();
    props.save();
    setChanged(false);
  };

  const onPlaceValueChange = (place) => {
    const { ptsPlaceID } = place;
    const newPlace = { ...place, ptsLocationAddressID };
    if (ptsPlaceID) {
      veryfySOP('Place', ptsPlaceID, null)
        .then((result) => {
          if (!mountedRef.current) return;
          setPlaceSopsAvailable(result);
        })
        .catch((e) => console.log(e));
    } else {
      placeSopsAvailable && setPlaceSopsAvailable(false);
    }
    addPlace(newPlace, no);
    setPlaceChanged(true);
  };

  const clearPlace = () => {
    updateData({ ...locationEmptyData }, no);
    setAddressNumber('');
    setPreDirection(null);
    setStreetName('');
    setStreetType(null);
    setPostDirection(null);
    setCity(null);
    setState(null);
    setPostalCode('');
    setCounty(null);
    setPtsAddressID(null);
    setNewPlace(null);
    setUnitType(null);
    setUnitIdentifier('');
    setNotes('');
    setCrossStreet('');
    placeSopsAvailable && setPlaceSopsAvailable(false);
    addressSopsAvailable && setAddressSopsAvailable(false);
    !changed && setChanged(true);
  };

  const validateForm = () => {
    if (ptsAddressID) return true;
    if (AddressNumber !== null && AddressNumber !== '' && StreetName && StreetType && City)
      return true;
    return false;
  };

  const onPredirectionChange = (ev, val) => {
    setPreDirection(val);
    !changed && setChanged(true);
  };

  const onStreetTypeChange = (ev, val) => {
    setStreetType(val);
    !changed && setChanged(true);
  };

  const onPostdirectionChange = (ev, val) => {
    setPostDirection(val);
    !changed && setChanged(true);
  };

  const onPrimarySet = (ev) => {
    setIsPrimary(ev.target.checked);
    !changed && setChanged(true);
  };

  console.log('Street Name ', StreetName);
  const renderActions = () => {
    const closePresent = !!props.clear;
    const savePresent = !!props.save;
    const disabled = !validForm || !changed;
    if (disableEdit) return '';
    return (
      <div className={classes.actions}>
        {savePresent && (
          <Fab color="secondary" aria-label="save" onClick={save} disabled={disabled} size="small">
            <SaveIcon />
          </Fab>
        )}
        {closePresent && (
          <Fab color="secondary" aria-label="close" onClick={clear} size="small">
            <CloseIcon />
          </Fab>
        )}
      </div>
    );
  };

  return (
    <ContainerCard
      title={title}
      actions={renderActions()}
      info={sopsAvailable ? { text: 'Selections have attached SOPs', type: 'error' } : null}>
      <div className={classes.form}>
        <Grid container className={classes.search}>
          <FormControl fullWidth className={classes.AddressLookup}>
            <AddressLookup2
              ptsAddresses={true}
              latLng={true}
              onPlaceValueSet={onPlaceValueChange}
              onAddressValueSet={onPlaceValueChange}
              defaultLocation={null}
              onReset={clearPlace}
              setPlace={newPlace}
              inputRef={focusRef}
            />
          </FormControl>
        </Grid>
      </div>
      <div className={classes.form}>
        <TextField
          className={classes.item}
          label="Number*"
          value={AddressNumber}
          onChange={onAddresNoChange}
          variant="outlined"
          size="small"
        />
        <Dictionary
          options="StreetDirections"
          className={classes.item}
          onChange={onPredirectionChange}
          value={PreDirection}
          label="Predirectional"
          compact="true"
          editingForm={editingForm}
        />
        <TextField
          className={classes.item}
          label="Street Name*"
          value={StreetName}
          onChange={onStreetNameChange}
          variant="outlined"
          size="small"
          error={!StreetName && !locationDescription}
        />
        <Dictionary
          options="StreetTypes"
          className={classes.item}
          onChange={onStreetTypeChange}
          value={StreetType}
          label="Street Type*"
          compact="true"
          editingForm={editingForm}
        />
        <Dictionary
          options="StreetDirections"
          className={classes.item}
          onChange={onPostdirectionChange}
          value={PostDirection}
          label="Postdirectional"
          compact="true"
          editingForm={editingForm}
        />
        <Dictionary
          className={classes.item}
          options="AddressUnitTypes"
          value={UnitType}
          onChange={onUnitTypeChange}
          label="Unit Type"
          compact="true"
          editingForm={editingForm}
        />
        <TextField
          className={classes.item}
          label="Unit"
          value={UnitIdentifier}
          onChange={onUnitChange}
          variant="outlined"
          size="small"
        />
        <Dictionary
          options="Cities"
          className={classes.item}
          onChange={(ev, val) => {
            setCity(val);
          }}
          value={City}
          label="City"
          error={changed && !City && !locationDescription}
          compact="true"
          editingForm={editingForm}
        />
        <Dictionary
          options="States"
          className={classes.item}
          onChange={(ev, val) => setState(val)}
          value={State}
          label="State"
          compact="true"
          editingForm={editingForm}
        />
        <TextField2
          className={classes.item}
          label="Postal Code"
          value={PostalCode}
          onChange={onPostcodeChange}
          error={PostalCode && !validatePostcode(PostalCode)}
          type="postcode"
          compact="true"
        />
        <Dictionary
          className={classes.item}
          options="Counties"
          value={County}
          onChange={onCountyChange}
          label="County"
          compact="true"
          editingForm={editingForm}
        />
        <FormControlLabel
          className={classes.item}
          control={
            <Checkbox checked={IsPrimary} onChange={onPrimarySet} className={classes.checkbox} />
          }
          label="Primary"
        />
        <Fills className={classes.item} />
      </div>
      <div className={classes.form}>
        <Tooltip title={Notes}>
          <TextField2
            className={classes.longField}
            label="Address Notes"
            value={Notes}
            onChange={onNotesChange}
            compact="true"
          />
        </Tooltip>
        <TextField2
          className={classes.longField}
          label="Cross Street"
          value={CrossStreet}
          onChange={onCrossStreetChange}
          compact="true"
        />
      </div>
      <p className={classes.requiredInfo}>
        * Either Location Description or address details are required.
      </p>
    </ContainerCard>
  );
}
