import { getService } from './service';
import store from '../config/configureStore';

export const getReport = (reportId, data) => {
  const service = getService('ssrs');
  service.timeout = 90000;
  return service.get(reportId, {
    query: { data },
  });
};

/**
 * @brief - this function checking the permission of export for agency
 * @param {object} data -  event object
 * @param {string} permissionType - property of agency object. param example: 'FireHouseEnabled', 'LawEnforcementRMSExport'
 * @param {string} agencyType - is permission checking for single agency or multiple agencies. param example: 'single', 'multiple'
 * @returns {boolean} return true or false, whether the particular permission is enable/disable for a agency.
 */
export const isEligibleForExport = (data, permissionType, agencyType) => {
  const state = store.store.getState();
  const { AgencyID } = data;
  const Agencies = state?.dictionary?.Agencies;
  if (!AgencyID || !Agencies || !Agencies.length) return false;
  let agency = false;
  if (agencyType === 'single') {
    agency = Agencies.find((a) => a.AgencyID === AgencyID && a[permissionType]);
  } else {
    agency = Agencies.find((a) =>
      AgencyID.split(', ').some((agency) => a.AgencyID === agency && a[permissionType])
    );
  }

  if (!agency) return false;
  return agency[permissionType];
};

export const sendRipAndRun = (ptsEventID) => {
  const service = getService('rip-and-run');
  service.timeout = 90000;
  return service.create({
    ptsEventID,
  });
};
