import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Badge, Menu, List, ListItem } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { handleError } from 'reducers/ErrorReducer';
import { sortObjArr } from 'utils/functions';
import { displayTime, minsToHours, getPassedTime } from 'reducers/TimeReducer';
import { setUnitNeedAttention } from 'reducers/UnitsNeedAttentionReducer';
import { safetyCheck } from 'reducers/UnitStatusReducer';
import { notifyPanel } from 'reducers/NotifierReducer';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const statusWidth = 90;

const useStyles = makeStyles((theme) => ({
  lstItem: {
    borderBottom: '1px solid grey',
    display: 'block',
    '& em': {
      fontStyle: 'normal',
      color: theme.palette.error.main,
      fontWeight: 600,
    },
  },
  split: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  menuInner: {
    width: 300,
  },
  list: {
    padding: 0,
    marginTop: -4,
    marginBottom: -4,
    fontSize: 13,
  },
  menu: {
    //marginLeft: -80,
  },
  table: {
    width: '100%',
    '& td:first-child button': {
      marginLeft: -4,
      marginBottom: -4,
    },
    '& td:nth-child(2)': {
      textAlign: 'right',
      '& button': {
        marginRight: -4,
        marginBottom: -4,
      },
    },
  },
  statusTd: {
    flex: `0 0 ${statusWidth + 5}px`,
    width: statusWidth + 5,
    boxSizing: 'border-box',
    marginBottom: 4,
  },
  status: {
    textAlign: 'center',
    '& > div': {
      fontSize: '1rem',
      fontWeight: 500,
      padding: '0 5px',
      background: theme.card.bg1,
      borderRadius: 5,
    },
    '& i': {
      padding: '2px 10px 1px',
      borderRadius: '4px',
      display: 'inline-block',
      textTransform: 'uppercase',
      fontWeight: 500,
      fontSize: 12,
      fontStyle: 'normal',
      width: statusWidth,
    },
  },
  actions: {
    textAlign: 'right',
    marginRight: -4,
    marginBottom: -4,
  },
}));

function UnitsNeedAttention(props) {
  const {
    unitsNeedAttention,
    UnitActions,
    UnitColors,
    units,
    events,
    notifyPanel,
    showSafetyChecks,
    unitAgencyFilter,
  } = props;
  const classes = useStyles();
  const [exUnits, setExUnits] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [timer, setTimer] = useState(0);
  const [started, setStarted] = useState(false);
  const timerRef = useRef(0);
  const intervalRef = useRef(0);
  const timeoutRef = useRef(0);
  const agencyFilter = props.agencyFilter || [];

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTimer(++timerRef.current);
    }, 30000);
    return () => {
      clearInterval(intervalRef.current);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      setTimer(++timerRef.current);
    }, 500);
    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line
  }, [events, units, UnitActions]);

  useEffect(() => {
    analyseUnits();
  }, [timer, unitAgencyFilter]);

  const analyseUnits = () => {
    if (!UnitActions || !props.units || !props.events) return;
    const units = [];

    // last action for all units
    props.units
      .filter((u) => agencyFilter.indexOf(u.AgencyID) === -1)
      .forEach((unit) => {
        const {
          ptsUnitID,
          Unit,
          AgencyID,
          Division,
          UnitStatus,
          UnitResources,
          UnitStatuses,
        } = unit;
        const newUnit = {
          ptsUnitID,
          Unit,
          AgencyID,
          Division,
          UnitResources,
          statuses: [UnitStatus],
        };
        if (UnitStatuses && UnitStatuses.length) {
          const { UnitStatus, Occurred } = unit.UnitStatuses[0];
          newUnit.Occurred = Occurred;
          newUnit.LastAction = UnitStatus;
          newUnit.passedMinutes = getPassedTime(Occurred);
          newUnit.passedTime = minsToHours(newUnit.passedMinutes);
        }
        units.push(newUnit);
      });

    // statuses of event units
    props.events.forEach((event) => {
      const { assignedUnits } = event;
      assignedUnits
        .filter((u) => agencyFilter.indexOf(u.AgencyID) === -1)
        .forEach((unit) => {
          const { ptsUnitID, UnitStatus } = unit;
          const idx = units.findIndex((u) => u.ptsUnitID === ptsUnitID);
          if (idx !== -1) {
            const statusExists = units[idx].statuses.find((status) => status === UnitStatus);
            if (!statusExists) units[idx].statuses.push(UnitStatus);
          }
        });
    });

    // caluculate exceeded time
    units.forEach((unit) => {
      const { passedMinutes } = unit;
      unit.exceeded = [];
      unit.statuses.forEach((UnitStatus) => {
        const WaitTime = UnitActions.find((u) => u.Code === UnitStatus)?.WaitTime;
        if (WaitTime && passedMinutes >= WaitTime)
          unit.exceeded.push({ exceededTime: passedMinutes - WaitTime, UnitStatus });
      });
      if (unit.exceeded.length) {
        unit.exceededMinTime = unit.exceeded.reduce(
          (val, current) => Math.min(val, current.exceededTime),
          99999999999999
        );
      }
    });

    // filter with selected agency & only units with exceeded time.
    const exUnits = sortObjArr(
      units.filter((unit) => unit.exceededMinTime && !unitAgencyFilter.includes(unit.AgencyID)),
      'exceededMinTime'
    );

    // ptsUnitIDs of units that need attention
    const attentionUnits = exUnits.map((unit) => unit.ptsUnitID);
    if (JSON.stringify(attentionUnits) !== JSON.stringify(unitsNeedAttention)) {
      exUnits
        .filter((unit) => unitsNeedAttention.indexOf(unit.ptsUnitID) == -1)
        .forEach((unit) => {
          if (started && showSafetyChecks) sendNotification(unit.Unit);
        });
      props.setUnitNeedAttention(attentionUnits);
    }

    setExUnits(exUnits);
    if (!started) setStarted(true);
  };

  const sendNotification = (Unit) => {
    const notificationOptsInfo = {
      title: 'Unit requires attention',
      message: `Unit ${Unit} requires attention`,
      position: 'tr',
    };
    notifyPanel(notificationOptsInfo, 'info');
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderBellFab = () => {
    return (
      <Fab
        size="small"
        className="mr-4"
        onClick={handleClick}
        disabled={!unitsNeedAttention?.length}>
        <Badge badgeContent={unitsNeedAttention?.length} color="error">
          <VerifiedUserIcon />
        </Badge>
      </Fab>
    );
  };

  const renderStatus = (unit, idx) => {
    const { UnitStatus } = unit;
    const statusColor = UnitColors[UnitStatus];
    return (
      <div className={classes.statusTd} key={idx}>
        <div className={classes.status}>
          <i style={{ background: statusColor, color: '#000' }}>{UnitStatus}</i>
        </div>
      </div>
    );
  };

  const renderUnit = (unit) => {
    const {
      ptsUnitID,
      Unit,
      AgencyID,
      UnitResources,
      passedTime,
      Occurred,
      exceeded,
      LastAction,
    } = unit;
    return (
      <ListItem key={unit.ptsUnitID} className={classes.lstItem}>
        <div className={classes.split}>
          <div>
            <strong>{Unit}</strong> | {AgencyID}
          </div>
          <div>{exceeded.map((status, idx) => renderStatus(status, idx))}</div>
        </div>
        <div>{UnitResources}</div>
        <div className={classes.split}>
          <div>
            <em>{passedTime}</em>
          </div>
          <div>
            <span>{LastAction}</span> <strong>{displayTime(Occurred)}</strong>
          </div>
        </div>
        <div className={classes.actions}>
          <Button size="small" color="primary" onClick={() => safetyCheck(ptsUnitID, Unit)}>
            Safety Check
          </Button>
        </div>
      </ListItem>
    );
  };

  const renderunitList = () => {
    return (
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl) && units.length > 0}
        onClose={handleClose}
        className={classes.menu}
        PaperProps={{
          className: classes.menuInner,
        }}>
        <List className={classes.list}>{exUnits.map(renderUnit)}</List>
      </Menu>
    );
  };

  return (
    <div>
      {renderBellFab()}
      {Boolean(anchorEl) && exUnits.length > 0 && renderunitList()}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    units: state.units,
    events: state.events,
    unitsNeedAttention: state.unitsNeedAttention,
    UnitActions: state.dictionary.UnitActions,
    UnitColors: state.config.colorPalette.Units,
    agencyFilter: state.unitSort.agencyFilter,
    showSafetyChecks: state.userSettings.showSafetyChecks,
    unitAgencyFilter: state.userSettings.unitAgencyFilter,
  };
};

export default connect(mapStateToProps, {
  handleError,
  setUnitNeedAttention,
  notifyPanel,
})(UnitsNeedAttention);
